import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import StatistiqueService from "../services/StatistiqueService";
import { useSelector } from "react-redux";

export default function DoughnutChartDemo() {
  const token = useSelector((state) => state.auth.token);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await StatistiqueService.statistiques(token);
        const responseData = response.data;
        if (responseData) {
          const backendData = responseData.data;
          const documentStyle = getComputedStyle(document.documentElement);

          const data = {
            labels: [
              "Total des Enrôlements",
              "Demandes de Copies d'arrêts",
              "Demandes de Grosses",
            ],
            datasets: [
              {
                data: [
                  backendData.totalDossiersPayes,
                  backendData.demandesCopies,
                  backendData.demandesGrosses,
                ],
                backgroundColor: ["#378006", "#dc3545", "#FF9800"],
              },
            ],
          };

          const options = {
            cutout: "70%",
            plugins: {
              legend: {
                position: "right",
                labels: {
                  color: documentStyle.getPropertyValue("--text-color"), // Couleur du texte
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          };

          setChartData(data);
          setChartOptions(options);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData(); // Appeler la fonction pour charger les données
  }, []);

  return (
    <div className="card-body">
      <h5
        className="card-title"
        style={{
          fontSize: "14px",
          borderBottom: "1px solid rgba(1, 41, 112, 0.2)",
        }}
      >
        Répartition des services
      </h5>
      <Chart
        type="doughnut"
        data={chartData}
        options={chartOptions}
        style={{
          position: "relative",
          width: "100%",
          height: "300px",
        }}
      />
    </div>
  );
}
