import http from "./http";

class DatesaudiencesService {
    index(token) {
        return http.get("/api/v01/web/datesaudiences/index", { headers: { 'Authorization': 'Bearer ' + token } })
    }
    store(data, token) {
        return http.post("/api/v01/web/datesaudiences/store", data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    delete(ref, token) {
        return http.delete(`/api/v01/web/datesaudiences/${ref}/delete`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    edit(ref, token) {
        return http.get(`/api/v01/web/datesaudiences/${ref}/edit`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    update(ref, data, token) {
        return http.put(`/api/v01/web/datesaudiences/${ref}/update`, data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    distinct(token) {
        return http.get("/api/v01/web/datesaudiences/distinct/liste", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    updateCreneau(token, ref, data) {
        return http.put(`/api/v01/web/datesaudiences/${ref}/updateCreneau`, data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    groupedDates(token) {
        return http.get("/api/v01/web/datesaudiences/groupedDates", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    filtrerDossier(data, token) {
        return http.post("/api/v01/web/filtrerDates", data, { headers: { 'Authorization': 'Bearer ' + token } })
    }
}

export default new DatesaudiencesService()