import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery";
//import 'datatables.net-bs5/js/dataTables.bootstrap5.min.mjs'
//import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import DemandeArretsService from "../../../services/DemandeArretsService";
import {
  afficherMessagesErreur,
  afficherMessagesSuccess,
  afficherOneMessageError,
  setDataTable,
} from "../../../utils/helpers";
import { Dialog } from "primereact/dialog";
import { app_url } from "../../../services/http";
import { Editor } from "@tinymce/tinymce-react";
import PaiementsService from "../../../services/PaiementsService";
import { getButtonConfig } from "../../../utils/ButtonUtils";

export default function DemandesArretsIndex() {
  //##########################/// METHODES ##########################///
  const profils = useSelector((state) => state.auth.profil);

  const [statuts, setStatuts] = useState([]);
  const [demandes_arrets, setDemandesArrets] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [detailLabel, setDetailLabel] = useState("");
  const [statut_id, setStatut_id] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [titreModal, setTitreModal] = useState("");
  const [allstatuts, setAllStatuts] = useState([]);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const handleCloseAnswerModal = () => setShowAnswerModal(false);
  const [objet, setObjet] = useState(
    "Réponse à votre demande de copie d'arrêt ou grosse"
  );
  const [message, setMessage] = useState("");
  const editorRef = useRef(null);
  const [file, setFile] = useState("");
  const [demande_arret_id, setDemandeArretId] = useState("");
  const [piecesItem, setPiecesItem] = useState("");

  const [showPiecesModal, setShowPiecesModal] = useState(false);
  const handleClosePiecesModal = () => setShowPiecesModal(false);

  const [dossierData, setDossierData] = useState({
    demandes_en_cours: 0,
    demandes_traitees: 0,
    demandes_remises: 0,
  });

  //get liste statuts
  const fetchStatuts = async () => {
    try {
      const response = await StatutsService.index(token);
      const liste_statuts = response.data.data;
      const filteredData = liste_statuts.filter(
        (option) =>
          option.libelle == "TRAITÉ" ||
          option.libelle == "REMIS" ||
          option.libelle == "EN COURS"
      );

      setStatuts(filteredData);
      setAllStatuts(filteredData);
    } catch (error) {
      console.error("Erreur lors de la récupération  :", error);
    }
  };

  const getStatuts = (statut_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Statut = statuts.find((item) => {
      return item.id == idToFind;
    });

    return Statut ? Statut.libelle : "N/A";
  };

  //get liste demandes arrets
  const fetchDemandesArrets = async () => {
    try {
      const response = await DemandeArretsService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDemandesArrets(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  const fetchDossierStats = async () => {
    try {
      const response = await DemandeArretsService.countDossiersByStatut(token);
      const responseData = response.data.data;

      setDossierData({
        //nouveaudossiers: responseData.nouveaudossiers,
        demandes_en_cours: responseData.demandes_en_cours,
        demandes_traitees: responseData.demandes_traitees,
        demandes_remises: responseData.demandes_remises,
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des statistiques :", error);
    }
  };

  const changeStatut = (ref, statut, num_arret) => {
    setDetailLabel(ref);
    setStatut_id(statut);
    setShowModal(true);
    setTitreModal(num_arret);

    //Filtrer les statut du modal selon le statut actuel
    if (getStatuts(statut) !== "EN COURS") {
      const libelleEnCours = statuts.find((opt) => opt.libelle === "EN COURS");
      const filtered = statuts.filter(
        (option) => parseInt(option.id) !== parseInt(libelleEnCours.id)
      );
      setAllStatuts(filtered);
    } else {
      setAllStatuts(allstatuts);
    }
  };

  const handleChangeStatut = async () => {
    let table = "demandes_arrets";
    setShowModal(false);
    await DemandeArretsService.changeStatutDemande(
      { ref: detailLabel, statut_id },
      token
    )
      .then((response) => {
        if (response.status == 200) {
          fetchDemandesArrets();
          fetchDossierStats();
          afficherMessagesSuccess(response.data.message);
        }
        setShowModal(false);
      })
      .catch((error) => {
        console.log(error);
        setShowModal(false);
      });
  };

  //Telecharger la demande
  const downloadDemande = async (path) => {
    if (path == null || path == "") {
      afficherOneMessageError(
        "Le document que vous voulez télécharger n'existe pas dans le système."
      );
      return;
    }
    const url = `${app_url}storage/${path}`;
    const link = document.createElement("a");
    link.href = url;

    // Trouver l'index de la dernière barre oblique "/"
    const lastSlashIndex = path.lastIndexOf("/");

    // Extraire la partie après la dernière barre oblique
    let lastPart = path.substring(lastSlashIndex + 1);

    link.download = lastPart; // Ajoutez un nom de fichier si nécessaire
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShowAnswerModal = (id, titre) => {
    setShowAnswerModal(true);
    setTitreModal(titre);
    setDemandeArretId(id);
  };

  const handleEditorChange = (e) => {
    setMessage(e.target.getContent());
  };

  // Fonction de gestion du changement de fichier
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  //envoie de la demande de liquidation
  const handleSendAnswer = async (event) => {
    event.preventDefault();

    /*const formData = new FormData();
    formData.append("fiche_liquidation", file);
    formData.append("objet", objet);
    formData.append("message", message);
    formData.append("demande_arret_id", demande_arret_id);*/

    try {
      setShowAnswerModal(false);

      const response = await DemandeArretsService.sendReponse(
        demande_arret_id,
        { message },
        token
      );
      if (response.status === 200) {
        setShowAnswerModal(false);
        fetchDemandesArrets();

        afficherMessagesSuccess(
          response.data.message ||
            "Bravo ! Votre message a été envoyé avec succès."
        );
      } else {
        setShowAnswerModal(false);
        afficherOneMessageError(
          response.data.message ||
            "Une erreur s'est produite lors de l'importation."
        );
      }
    } catch (error) {
      //console.error("Une erreur s'est produite :", error);
      setShowAnswerModal(false);

      afficherMessagesErreur(error.response.data);
    }
  };

  const handleShowPiecesModal = (param) => {
    setShowPiecesModal(true);
    setTitreModal(param.num_arret);
    setDemandeArretId(param.id);
    setPiecesItem(param);
  };

  const downloadQuittance = async (reference) => {
    const isEncrypt = false;
    await PaiementsService.downloadDemandeQuittance(reference, isEncrypt, token)
      .then((response) => {
        if (response.status == 200) {
          // Créer un lien de téléchargement pour le fichier PDF
          const blob = new Blob([response.data], { type: "application/pdf" });
          const urlBlob = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = urlBlob;
          link.setAttribute("download", `quittance_${reference}.pdf`); // Nom du fichier téléchargé
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // Nettoyer le DOM après le téléchargement

          afficherMessagesSuccess("Quittance téléchargée avec succès.");
        }
      })
      .catch((error) => {
        console.log(error);
        afficherMessagesErreur(error.response.data);
      });
  };

  useEffect(() => {
    fetchDemandesArrets();
    fetchStatuts();
    fetchDossierStats();
    $("#submenu-4").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Demandes de copie d'arrêts{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 pb-3 align-items-center justify-content-between">
              <div className="col px-4">
                <Link className="text-danger">
                  <h2>{dossierData.demandes_en_cours}</h2>
                </Link>
                <span className="">En cours</span>
              </div>
              <div
                className="col px-4"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <Link className="text-success">
                  <h2>{dossierData.demandes_traitees}</h2>
                </Link>
                <span className="">Traité</span>
              </div>
              <div
                className="col px-4"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <Link className="text-primary">
                  <h2>{dossierData.demandes_remises}</h2>
                </Link>
                <span className="">Remis</span>
              </div>
            </div>
            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Liste des demandes de copie d'arrêt ou grosse
                </h3>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Requérant</th>
                        <th className="">Objet</th>
                        <th className="">Reference dossier</th>
                        <th className="">Num. Arrêt</th>
                        <th className="">Statut</th>
                        {profils.includes("SUPERADMIN") && (
                          <th className="">Auteur</th>
                        )}
                        <th className="">Date</th>
                        <th className="">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {demandes_arrets.length > 0 &&
                        demandes_arrets.map((item, index) => (
                          <tr
                            key={index}
                            className={`${
                              item.get_frais_paiement?.libelle === "Grosse"
                                ? "table-warning"
                                : "table-secondary"
                            }`}
                          >
                            <td className="cell">{index + 1}</td>
                            <td className="cell">
                              {item.nom + " " + item.prenoms}
                              <br />
                              {item.email}
                              <br />
                              {item.telephone}
                            </td>
                            <td className="cell">
                              {item.get_frais_paiement?.libelle}
                            </td>
                            <td className="cell">
                              {item.get_dossier
                                ? item.get_dossier.reference_dossier
                                : item.reference_manuelle}
                            </td>
                            <td className="cell">{item.num_arret}</td>
                            <td className="cell">
                              <span
                                className={
                                  getStatuts(item.statut_id) == "EN COURS"
                                    ? "badge bg-danger"
                                    : getStatuts(item.statut_id) == "REMIS"
                                    ? "badge bg-primary"
                                    : "badge bg-success"
                                }
                              >
                                {getStatuts(item.statut_id)}
                              </span>
                            </td>
                            {profils.includes("SUPERADMIN") && (
                              <td className="cell">
                                {item.get_createdby &&
                                  item.get_createdby?.nom +
                                    " " +
                                    item.get_createdby?.prenoms}
                              </td>
                            )}
                            <td className="cell">
                              <span>
                                {dayjs(item.created_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="cell">
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  className={`btn btn-sm btn-${
                                    getButtonConfig("STATUT")?.color
                                  }`}
                                  onClick={() => downloadDemande(item.path)}
                                  title="Télécharger la demande"
                                >
                                  <i className="bi bi-file-earmark-word h6"></i>
                                </Button>
                                <Button
                                  className={`btn btn-sm btn-${
                                    getButtonConfig("DELETE")?.color
                                  }`}
                                  onClick={() =>
                                    downloadQuittance(item.reference_demande)
                                  }
                                  title="Télécharger la quittance"
                                >
                                  <i className="bi bi-file-earmark-pdf h6"></i>
                                </Button>
                                <Button
                                  className="btn btn-sm btn-success"
                                  onClick={() =>
                                    handleShowAnswerModal(
                                      item.id,
                                      item.reference_demande
                                    )
                                  }
                                  title="Répondre à la demande"
                                >
                                  <i className="bi bi-reply h6"></i>
                                </Button>
                                {getStatuts(item.statut_id) !== "REMIS" && (
                                  <Button
                                    className={`btn btn-sm btn-${
                                      getButtonConfig("EDIT")?.color
                                    }`}
                                    onClick={() =>
                                      changeStatut(
                                        item.ref,
                                        item.statut_id,
                                        item.num_arret
                                      )
                                    }
                                    title="Changer le statut de la demande"
                                  >
                                    <i className="bi bi-check2-circle h6"></i>
                                  </Button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}

                {/* Modal change statut*/}
                <Dialog
                  header={titreModal}
                  visible={showModal}
                  onHide={handleCloseModal}
                  style={{
                    width: "700px",
                    borderBottom: "1px solid grey",
                    color: "GrayText",
                  }}
                  headerStyle={{ background: "#f0f0f0" }}
                >
                  <div className="p-fluid">
                    <div className="row">
                      <hr />
                      <p className="text-center fw-bold">
                        Changer le statut de la demande{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <Col md={12} sm={12} className="formx-groupx">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Statut <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Select
                          className=""
                          id="statut_id"
                          name="statut_id"
                          value={statut_id}
                          required
                          onChange={(e) => {
                            setStatut_id(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            -- Sélectionnez --
                          </option>
                          {allstatuts.length > 0 &&
                            allstatuts.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.libelle}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </div>

                  <div className="p-dialog-footerx mt-4">
                    {/* Pied de page du modal */}
                    <Button
                      type="submit"
                      variant="warning me-3"
                      onClick={handleChangeStatut}
                    >
                      Enregistrer
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Annuler
                    </Button>
                  </div>
                </Dialog>

                {/* Modal réponse*/}
                <Dialog
                  header={`Répondre à la demande ${titreModal}`}
                  visible={showAnswerModal}
                  onHide={handleCloseAnswerModal}
                  style={{
                    width: "700px",
                    borderBottom: "1px solid grey",
                    color: "GrayText",
                  }}
                  headerStyle={{ background: "#f0f0f0" }}
                >
                  <div className="row mt-3">
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group>
                        <Form.Label className="fw-bold">
                          Objet <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border border rounded-2 control-label"
                          placeholder="Objet"
                          name="objet"
                          aria-label="objet"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          value={objet}
                          onChange={setObjet}
                        />
                      </Form.Group>
                    </Col>
                    {/**
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Fiche de liquidation <i className="text-danger">*</i>
                        </Form.Label>
                        <input
                          type="file"
                          className="form-control border-success"
                          accept=".pdf, .docx, .doc"
                          name="file"
                          onChange={handleFileChange}
                          required
                        />
                      </Form.Group>
                    </Col> */}
                    <Col md={12} sm={12} className="formx-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Message <i className="text-danger"></i>
                        </Form.Label>
                        <Editor
                          apiKey="quxjd5vswwl6oxto466w44izsbdc3w35w5u9mz5hur625yp4"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={{
                            height: 500,
                            menubar: true,
                            theme: "silver",
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                          initialValue=""
                          onChange={handleEditorChange}
                        />
                      </Form.Group>
                    </Col>
                  </div>

                  <div className="p-dialog-footerx mt-4">
                    {/* Pied de page du modal */}
                    <Button
                      type="submit"
                      variant="warning me-3"
                      onClick={handleSendAnswer}
                    >
                      Envoyer
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleCloseAnswerModal}
                    >
                      Annuler
                    </Button>
                  </div>
                </Dialog>

                {/* Modal preuve*/}
                <Dialog
                  header={"Détails sur la demande " + titreModal}
                  maximizable
                  visible={showPiecesModal}
                  onHide={handleClosePiecesModal}
                  style={{
                    width: "700px",
                    borderBottom: "1px solid grey",
                    color: "GrayText",
                  }}
                  headerStyle={{ background: "#f0f0f0" }}
                >
                  <div className="p-fluid">
                    <div className="row">
                      <hr />
                      <p className="text-center fw-bold">
                        Les pièces de la demande
                      </p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label htmlFor="">Fiche de liquidation</label>
                    <iframe
                      src={`${app_url}/storage/demandes_arrets/${piecesItem.fiche_liquidation}`}
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                      title="Liquidation Document"
                    />
                  </div>
                  <div className="row">
                    <label htmlFor="">Fichier de paiement DGI</label>
                    <iframe
                      src={`${app_url}/storage/demandes_arrets/${piecesItem.path_fichier_impot}`}
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                      title="Liquidation Document"
                    />
                  </div>
                  <div className="row">
                    <label htmlFor="">Preuve de paiement</label>
                    <iframe
                      src={`${app_url}/storage/demandes_arrets/${piecesItem.path_fichier_paiement}`}
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                      title="Liquidation Document"
                    />
                  </div>

                  <div className="p-dialog-footerx mt-4">
                    <Button
                      variant="secondary"
                      onClick={handleClosePiecesModal}
                    >
                      Fermer
                    </Button>
                  </div>
                </Dialog>
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
