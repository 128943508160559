import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Importez les styles par défaut de la bibliothèque
import AssujettiesService from "../../../services/AssujettiesService";
import CivilitesService from "../../../services/CivilitesService";
import { RadioButton } from "primereact/radiobutton";
import { ProgressSpinner } from "primereact/progressspinner";
import TypeActeursService from "../../../services/TypeActeursService";

import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

const schema = Joi.object({
  type_personne: Joi.string()
    .valid("PP", "PM")
    .required()
    .messages({
      "any.required": "Le type de personne est obligatoire.",
      "any.only": "Le type de personne doit être 'Personne morale' ou 'Personne physique'.",
    }),

  raison_sociale: Joi.string()
    .when("type_personne", {
      is: "PM",
      then: Joi.string().min(3).max(100).required(),
      otherwise: Joi.string().allow(""),
    })
    .messages({
      "string.empty": `La raison sociale ne peut pas être vide si type personne est "Personne morale".`,
      "string.min": `La raison sociale doit contenir au moins {#limit} caractères.`,
      "string.max": `La raison sociale ne peut pas dépasser {#limit} caractères.`,
    }),

  civilite_id: Joi.string()
    .when("type_personne", {
      is: "PP",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    })
    .messages({
      "any.required": `La civilité est obligatoire pour une personne physique.`,
    }),

  nom: Joi.string()
    .when("type_personne", {
      is: "PP",
      then: Joi.string().min(2).max(100).required(),
      otherwise: Joi.string().allow(""),
    })
    .messages({
      "string.empty": `Le nom est requis pour une personne physique.`,
      "string.min": `Le nom doit contenir au moins {#limit} caractères.`,
      "string.max": `Le nom ne peut pas dépasser {#limit} caractères.`,
    }),

  prenoms: Joi.string()
    .when("type_personne", {
      is: "PP",
      then: Joi.string().min(3).max(100).required(),
      otherwise: Joi.string().allow(""),
    })
    .messages({
      "string.empty": `Le prénom est requis pour une personne physique.`,
      "string.min": `Le prénom doit contenir au moins {#limit} caractères.`,
      "string.max": `Le prénom ne peut pas dépasser {#limit} caractères.`,
    }),

  rccm: Joi.string()
    .min(3)
    .max(100)
    .allow("")
    .messages({
      "string.min": `Le RCCM doit contenir au moins {#limit} caractères.`,
      "string.max": `Le RCCM ne peut pas dépasser {#limit} caractères.`,
    }),

  ifu: Joi.string()
    .min(3)
    .max(13)
    .allow("")
    .messages({
      "string.min": `L'IFU doit contenir au moins {#limit} caractères.`,
      "string.max": `L'IFU ne peut pas dépasser {#limit} caractères.`,
    }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow("")
    .messages({
      "string.email": "L'adresse email doit être valide.",
    }),

  telephone: Joi.string()
  .pattern(/^\+[0-9]{1,3}[0-9]{7,}$/)
    .allow("")
    .messages({
      "string.pattern.base": `Le numéro de téléphone doit être un numéro valide.`,
    }),

  adresse: Joi.string()
    .max(500)
    .allow("")
    .messages({
      "string.max": `L'adresse ne peut pas dépasser {#limit} caractères.`,
    }),

  is_acteur: Joi.number()
    .valid(0, 1)
    .required()
    .messages({
      "any.required": `Veuillez indiquer si c'est un acteur de justice`,
      "any.only": `"Est-ce un acteur de justice" doit être OUI ou NON.`,
    }),

  type_acteur_id: Joi.string()
    .when("is_acteur", {
      is: 1,
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    })
    .messages({
      "any.required": `Le type d'acteur est requis si "is_acteur" est OUI.`,
    }),
});

export default function AssujettiesCreate() {
  //Déclaration des champs du formulaire
  const [is_acteur, setIsActeur] = useState(0);
  const [civilites, setCivilites] = useState([]);
  const [civilite_id, setCivilite_id] = useState("");
  const [type_personne, setType_personne] = useState("PM");
  const [raison_sociale, setRaison_sociale] = useState("");
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [rccm, setRccm] = useState("");
  const [ifu, setIfu] = useState("");
  const token = useSelector((state) => state.auth.token);
  const [type_acteurs, setTypeacteurs] = useState([]);
  const [type_acteur_id, setTypeacteur_id] = useState("");

  const [loading, setLoading] = useState(false); // État pour le chargement

  const handleOptionChange = (value) => {
    setIsActeur(value);
    setTypeacteur_id("");
  };

  const navigate = useNavigate();

  const handleChange = (value, country) => {
    setTelephone(value);
    setTelephoneError("");
  };

  //get liste civilites
  const fetchCivilites = async () => {
    await CivilitesService.index(token)
      .then((response) => {
        setCivilites(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*const datacivilites = civilites.map((item) => {
    return {
      label: item.libelle,
      value: item.id,
    };
  });*/

  //Liste des types d'acteurs
  const fetchTypeacteurs = async () => {
    await TypeActeursService.index(token)
      .then((response) => {
        if (response.status === 200) {
          setTypeacteurs(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [validationsErrors, setValidationsErrors] = useState([]);

  const onSubmit = async (e) => {
    const addFormData = {
      type_personne,
      raison_sociale,
      civilite_id,
      nom,
      prenoms,
      rccm,
      ifu,
      email,
      telephone,
      adresse,
      is_acteur,
      type_acteur_id,
    };

    const validation = schema.validate(addFormData, { abortEarly: false });
    if (validation.error) {
      console.error(validation.error.details);
      // Stocker les messages d'erreurs
      const errorMessages = Object.values(validation.error.details).map(
        (error) => error.message
      );
      setValidationsErrors(errorMessages);
      return;
    }
    //e.preventDefault();
    setLoading(true); // Début du chargement

    try {
      /*const formData = new FormData();

      // Ajoutez les champs communs
      formData.append("email", email);
      formData.append("telephone", telephone);
      formData.append("adresse", adresse);
      formData.append("rccm", rccm);
      formData.append("ifu", ifu);
      formData.append("civilite_id", civilite_id);
      formData.append("type_personne", type_personne);
      formData.append("raison_sociale", raison_sociale);
      formData.append("nom", nom);
      formData.append("prenoms", prenoms);
      formData.append("is_acteur", is_acteur);
      formData.append("type_acteur_id", type_acteur_id);*/

      // Enregistrez le formulaire
      const response = await AssujettiesService.store(addFormData, token);
      if (response.data.status == true) {
        // Redirigez l'utilisateur vers l'index
        navigate(myroutes.assujetties_index);
        //console.log(response.data)
        // Affichez un message de succès
        Swal.fire({
          icon: "success",
          title: "Bravo !",
          text: response.data.message,
        });

        // Réinitialisez les données du formulaire
        setRaison_sociale("");
        setNom("");
        setPrenoms("");
        setEmail("");
        setTelephone("");
        setAdresse("");
        setRccm("");
        setIfu("");
        setType_personne("PM");
        setIsActeur(0);
        setTypeacteur_id("");
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message + " " + response.data.data,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Vous avez mal renseigné les champs ou cet élément existe déjà dans la base de données",
      });
      console.error(error);
    } finally {
      setLoading(false); // Fin du chargement après le traitement
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
  });

  useEffect(() => {
    fetchCivilites();
    fetchTypeacteurs();
  }, []);

  return (
    <Layout>
      <main id="main" className="main ">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">Assujetties </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h1 className="app-page-title mb-0">Enregistrement </h1>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-settings shadow-lg mb-5">
              <div className="app-card-body py-3">
                <div className="row">
                  {validationsErrors.length > 0 && (
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <ul>
                          {validationsErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <Form
                  className="mx-3 settings-form p-4"
                  style={{ textAlign: "left" }}
                >
                  <Row>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3 d-flexx">
                        <Form.Label
                          className="fw-bold mb-3"
                          style={{
                            fontSize: "18px",
                            color: "tomato",
                            fontStyle: "italicx",
                          }}
                        >
                          Est-ce une personne physique ?{" "}
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Check
                          {...register("type_personne")}
                          name="type_personne"
                          type="switch"
                          id="type_personneSwitch"
                          label={type_personne === "PP" ? "Oui" : "Non"}
                          onChange={() =>
                            setType_personne(
                              type_personne === "PP" ? "PM" : "PP"
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={12}
                      sm={12}
                      className="form-group"
                    >
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Dénomination sociale <i className="text-danger">{type_personne === "PM" && "*"}</i>
                        </Form.Label>
                        <Form.Control
                          {...register("raison_sociale")}
                          className="border border rounded-2"
                          placeholder={type_personne === "PP" ? "Renseigner la raison sociale s'il y a lieu" : ""}
                          name="raison_sociale"
                          aria-label="raison_sociale"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          onChange={(e) => setRaison_sociale(e.target.value)}
                          value={raison_sociale}
                        />
                        {errors.raison_sociale && (
                          <span className="text-danger">
                            {errors.raison_sociale.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={2} sm={2} className="formx-groupx">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Civilité{" "}
                          <i className="text-danger">
                            {type_personne === "PP" && "*"}
                          </i>
                        </Form.Label>
                        <Controller
                          name="civilite_id"
                          control={control}
                          render={({ field }) => (
                            <Form.Select
                              {...field}
                              className=""
                              id="civilite_id"
                              name="civilite_id"
                              required={type_personne === "PP" ? true : false}
                              onChange={(e) => {
                                setCivilite_id(e.target.value);
                              }}
                            >
                              <option value="" selected>
                                --
                              </option>
                              {civilites.length > 0 &&
                                civilites.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.libelle}
                                  </option>
                                ))}
                            </Form.Select>
                          )}
                        />
                        {errors.civilite_id && (
                          <span className="text-danger">
                            {errors.civilite_id.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={5} sm={5} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Nom{" "}
                          <i className="text-danger">
                            {type_personne === "PP" && "*"}
                          </i>
                        </Form.Label>
                        <Form.Control
                          {...register("nom")}
                          className="border border rounded-2"
                          placeholder=""
                          name="nom"
                          aria-label="nom"
                          maxLength={100}
                          minLength={2}
                          type="text"
                          required={type_personne === "PP" ? true : false}
                          onChange={(e) => setNom(e.target.value)}
                          value={nom}
                        />
                        {errors.nom && (
                          <span className="text-danger">
                            {errors.nom.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={5} sm={5} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Prénom (s){" "}
                          <i className="text-danger">
                            {type_personne === "PP" && "*"}
                          </i>
                        </Form.Label>
                        <Form.Control
                          {...register("prenoms")}
                          className="border border rounded-2"
                          placeholder=""
                          name="prenoms"
                          aria-label="prenoms"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          required={type_personne === "PP" ? true : false}
                          onChange={(e) => setPrenoms(e.target.value)}
                          value={prenoms}
                        />
                        {errors.prenoms && (
                          <span className="text-danger">
                            {errors.prenoms.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">RCCM </Form.Label>
                        <Form.Control
                          {...register("rccm")}
                          className="border border rounded-2"
                          placeholder=""
                          name="rccm"
                          aria-label="rccm"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          onChange={(e) => setRccm(e.target.value)}
                          value={rccm}
                        />
                        {errors.rccm && (
                          <span className="text-danger">
                            {errors.rccm.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">IFU </Form.Label>
                        <Form.Control
                          {...register("ifu")}
                          className="border border rounded-2"
                          onChange={(e) => setIfu(e.target.value)}
                          value={ifu}
                          placeholder=""
                          name="ifu"
                          aria-label="ifu"
                          maxLength={13}
                          minLength={3}
                          type="number"
                        />
                        {errors.ifu && (
                          <span className="text-danger">
                            {errors.ifu.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Email <i className="text-danger"></i>
                        </Form.Label>
                        <Form.Control
                          {...register("email")}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          className="border border rounded-2"
                          placeholder=""
                          name="email"
                          aria-label="email"
                          maxLength={100}
                          minLength={3}
                          type="email"
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Téléphone <i className="text-danger"></i>
                          {"  "}
                          <i
                            className="text-info bi bi-question-circle-fill"
                            title="Veuillez choisir le drapeau et compléter votre numéro"
                          ></i>
                        </Form.Label>
                        <div>
                          <PhoneInput
                            defaultCountry="BJ"
                            value={telephone}
                            onChange={handleChange}
                            inputClass={`form-input`}
                            containerStyle={{
                              border: "",
                              borderRadius: "6px",
                              height: "50px",
                            }}
                            inputStyle={{
                              width: "80%",
                              border: "none",
                              outline: "none",
                              height: "100px",
                            }}
                            placeholder="61 00 00 00"
                          />
                        </div>
                        {errors.telephone && (
                          <span className="text-danger">
                            {errors.telephone.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Adresse<i className="text-danger"></i>
                        </Form.Label>
                        <textarea
                          {...register("adresse")}
                          name="adresse"
                          onChange={(e) => setAdresse(e.target.value)}
                          value={adresse}
                          id=""
                          cols="30"
                          rows="3"
                          className="form-control summernote border border rounded-2"
                          placeholder="Boîte postale, localisation, ville, ..."
                        ></textarea>
                        {errors.adresse && (
                          <span className="text-danger">
                            {errors.adresse.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group flex justify-content-center mb-4 fw-bold">
                        <span
                          className="me-3 p-2 mb-3"
                          style={{
                            color: "tomato",
                            backgroundColor: "",
                            fontSize: "18px",
                          }}
                        >
                          Est-ce un acteur de justice (Avocat ou Huissier) ?
                        </span>
                        <div className="p-field-checkbox">
                          <div className="p-formgroup-inline d-flex mt-3">
                            <div className="p-field-radiobutton me-4">
                              <RadioButton
                                className="me-2"
                                inputId="oui"
                                name="oui"
                                value={1}
                                onChange={(e) => handleOptionChange(e.value)}
                                checked={is_acteur === 1}
                              />
                              <label htmlFor="oui">Oui</label>
                            </div>
                            {"  "}
                            <div className="p-field-radiobutton">
                              <RadioButton
                                className="me-2"
                                inputId="non"
                                name="non"
                                value={0}
                                onChange={(e) => handleOptionChange(e.value)}
                                checked={is_acteur === 0}
                              />
                              <label htmlFor="non">Non</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    {is_acteur == 1 && (
                      <Col md={5} sm={6} className="formx-groupx">
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-boldx">
                            <span
                              className="mb-3"
                              style={{ color: "tomato", fontSize: "18px" }}
                            >
                              Type acteur{" "}
                            </span>
                            <i className="text-danger">*</i>
                          </Form.Label>
                          <Controller
                            name="type_acteur_id"
                            control={control}
                            render={({ field }) => (
                              <Form.Select
                                {...field}
                                className=""
                                id="type_acteur_id"
                                name="type_acteur_id"
                                value={type_acteur_id}
                                onChange={(e) => {
                                  setTypeacteur_id(e.target.value);
                                }}
                              >
                                <option value="" selected>
                                  --
                                </option>
                                {type_acteurs.length > 0 &&
                                  type_acteurs.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.libelle}
                                    </option>
                                  ))}
                              </Form.Select>
                            )}
                          />
                          {errors.type_acteur_id && (
                            <span className="text-danger">
                              {errors.type_acteur_id.message}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                  <Button
                    type="button"
                    variant="warning me-3"
                    disabled={loading}
                    onClick={onSubmit}
                  >
                    Enregistrer
                  </Button>{" "}
                  {/* Désactiver le bouton pendant le chargement */}
                  <Button
                    variant="secondary"
                    onClick={() => navigate(myroutes.assujetties_index)}
                  >
                    Annuler
                  </Button>
                </Form>
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}
          </div>
          {/*//container-fluid*/}
        </div>

        <Modal show={loading} backdrop="static" keyboard={false} centered>
          <Modal.Body className="text-center">
            <ProgressSpinner />
            <p className="my-3 fw-bold" style={{ fontSize: "18px" }}>
              Veuillez patienter pendant l'enregistrement des données...
            </p>
          </Modal.Body>
        </Modal>

        {/*//app-content*/}
      </main>
    </Layout>
  );
}
