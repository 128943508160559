import { Link, useLocation, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import StatutsService from "../../../services/StatutsService";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery";
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import DatesaudiencesService from "../../../services/DatesaudiencesService";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import { Tag } from "primereact/tag";

import parse from "html-react-parser";
import { Button, Col, Form } from "react-bootstrap";
import {
  convertDateToTexte,
  copyToClipboard,
  setDataTable,
} from "../../../utils/helpers";
import ChambresService from "../../../services/ChambresService";
import ChambresmembresService from "../../../services/ChambresmembresService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { getButtonConfig } from "../../../utils/ButtonUtils";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";


export default function DatesaudiencesIndex() {
  //##########################/// METHODES ##########################///
  const [datesaudiences, setDatesaudiences] = useState([]);
  const [alldatesaudiences, setAllDatesaudiences] = useState([]);
  const [statuts, setStatuts] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const profils = useSelector((state) => state.auth.profil);
  const [chambres, setChambres] = useState([]);
  const [chambre_id, setChambre_id] = useState("");
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  //get liste dates audiences
  const fetchDatesaudiences = async () => {
    try {
      setLoading(true);
      const response = await DatesaudiencesService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        if (location.state && location.state.chambre_id) {
          const chambreSelected = location.state.chambre_id;
          setChambre_id(chambreSelected);
          //Afficher dataTable
          //setDataTable();

          //Filtrer les dates audiences par chambre
          const filteredDatesAudiences = responseData.data.filter(
            (item) => parseInt(item.chambre_id) === parseInt(chambreSelected)
          );
          setDatesaudiences(filteredDatesAudiences);
        }
        setAllDatesaudiences(responseData.data);
        setLoading(false);
        //setDatesaudiences(responseData.data)
      } else {
        setLoading(false);
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    //setDataTable();
  };

  //get liste statuts
  const fetchStatuts = async () => {
    try {
      const response = await StatutsService.index(token);
      const statutsData = response.data.data;
      setStatuts(statutsData);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des parties membres :",
        error
      );
    }
  };

  //redirection sur la page edit sans afficher les id dans l'url
  const goToEdit = (param) => {
    navigate(myroutes.datesaudiences_edit, { state: { ref: param } });
  };

  const goToDossier = (dossier_ref) => {
    navigate(`${myroutes.dossiers_show}/${dossier_ref}`);
    //navigate(myroutes.dossiers_show, { state: { ref: dossier_ref } })
  };

  const handleCopyReference = (reference, itemId) => {
    copyToClipboard(reference);
  };

  //get liste chambres
  const fetchChambres = async () => {
    try {
      const response = await ChambresService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        if (
          profils.includes("GEC") ||
          profils.includes("AGEC") ||
          profils.includes("ADMIN") ||
          profils.includes("SUPERADMIN")
        ) {
          setChambres(responseData.data);
        }
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  //get liste chambres de l'utilisateur
  const fetchChambresMembres = async () => {
    try {
      const response = await ChambresmembresService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        if (
          !profils.includes("GEC") &&
          !profils.includes("AGEC") &&
          !profils.includes("ADMIN") &&
          !profils.includes("SUPERADMIN")
        ) {
          const filtered = responseData.data.filter(
            (item) => parseInt(item.utilisateur_id) === parseInt(user.id)
          );
          setChambres(
            filtered.map((opt) => {
              return {
                id: opt.chambre_id,
                libelle: opt.get_chambre && opt.get_chambre.libelle,
              };
            })
          );
        }
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  const handleSelectChambre = (e) => {
    const chambreSelected = e.target.value;
    setChambre_id(chambreSelected);
    //Afficher dataTable
    //setDataTable();

    //Filtrer les dates audiences par chambre
    const filteredDatesAudiences = alldatesaudiences.filter(
      (item) => parseInt(item.chambre_id) === parseInt(chambreSelected)
    );
    setDatesaudiences(filteredDatesAudiences);
  };

  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      jour: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      reference_dossier: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      chambre: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      motif_renvoie: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      observation: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };


  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value; // Mise à jour du filtre global

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
        <div className="flex justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Recherche globale" />
            </IconField>
        </div>
    );
};

  const header = renderHeader();

  useEffect(() => {
    fetchDatesaudiences();
    fetchStatuts();
    fetchChambresMembres();
    fetchChambres();
    initFilters();

    $("#datesaudiences-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <BreadcrumbComponent
              params={{
                isParametre: false,
                libelle: "Dates d'audiences",
                title: "Audiences",
              }}
              routeName={myroutes.datesaudiences_index}
            ></BreadcrumbComponent>

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Dossiers aux rôles &nbsp;
                  {profils.includes("GEC") && (
                    <Link
                      className="btn btn-success btn-sm"
                      to={myroutes.datesaudiences_create}
                      title="Ajouter"
                    >
                      <i className="bi bi-plus"></i>Ajouter
                    </Link>
                  )}
                </h3>
              </div>

              <Col md={6} sm={6} className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Chambre<i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Select
                    className=""
                    id="chambre_id"
                    name="chambre_id"
                    value={chambre_id}
                    required
                    onChange={handleSelectChambre}
                  >
                    <option value="" selected>
                      -- Sélectionnez --
                    </option>
                    {chambres.length > 0 &&
                      chambres.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.libelle}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <DataTable
                    value={datesaudiences}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    loading={loading}
                    emptyMessage="Aucune données disponibles dans la chambre sélectionnée."
                    stripedRows
                    size="small"
                    sortMode="multiple"
                    showGridlines
                    dataKey="id"
                    removableSort
                    filters={filters}
                    header={header}
                    globalFilterFields={['get_calendrier.jour', 'get_dossier.reference_dossier', 'get_chambre.libelle', 'get_dossier?.get_statut_dossier.libelle', 'motif_renvoie', 'observation']}
                    onFilter={(e) => setFilters(e.filters)}
                  >
                    <Column
                      filterField="get_calendrier.jour"
                      header="Date d'audience"
                      body={(item) =>
                        convertDateToTexte(item.get_calendrier.jour)
                      }
                    />
                    <Column
                      field="get_dossier.reference_dossier"
                      sortable
                      header="Référence dossier"
                      body={(item) =>
                        profils.includes("GEC") ||
                        profils.includes("AGEC") ||
                        profils.includes("ADMIN") ||
                        profils.includes("SUPERADMIN") ? (
                          <Link
                            to={`${myroutes.dossiers_show}/${item.get_dossier.ref}`}
                            title="Afficher le dossier"
                          >
                            <b>{item.get_dossier.reference_dossier}</b>
                          </Link>
                        ) : (
                          <b>{item.get_dossier.reference_dossier}</b>
                        )
                      }
                    />
                    <Column
                      field="get_chambre.libelle"
                      header="Chambre"
                      body={(item) => item.get_chambre.libelle}
                    />
                    <Column
                      field="get_dossier?.get_statut_dossier.libelle"
                      header="Statut"
                      body={(item) => (
                        <span
                          className={`badge ${
                            item.get_dossier?.get_statut_dossier.libelle ===
                            "Nouveau"
                              ? "text-bg-success"
                              : item.get_dossier?.get_statut_dossier.libelle ===
                                "Renvoyé"
                              ? "text-bg-danger"
                              : "text-bg-secondary"
                          }`}
                        >
                          {item.get_dossier?.get_statut_dossier.libelle}
                        </span>
                      )}
                    />
                    <Column
                      field="motif_renvoie"
                      sortable
                      header="Motif renvoi"
                      body={(item) =>
                        item.motif_renvoie != null
                          ? parse(item.motif_renvoie)
                          : item.motif_renvoie
                      }
                    />
                    <Column
                      field="observation"
                      sortable
                      header="Observation"
                      body={(item) =>
                        item.observation != null
                          ? parse(item.observation)
                          : item.observation
                      }
                    />
                    {(profils.includes("GEC") ||
                      profils.includes("AGEC") ||
                      profils.includes("ADMIN") ||
                      profils.includes("SUPERADMIN")) && (
                      <Column
                        field="action"
                        header="Action"
                        body={(item) => (
                          <div className="d-flex justify-content-evenly">
                            <Button
                              className={`btn btn-sm btn-${
                                getButtonConfig("EDIT")?.color
                              }`}
                              onClick={() => goToEdit(item.ref)}
                              title="Modifier"
                            >
                              <i
                                className={`fa ${
                                  getButtonConfig("EDIT")?.icon
                                }`}
                              ></i>
                            </Button>{" "}
                            &nbsp;&nbsp;
                            <Button
                              className="btn btn-sm btn-success"
                              onClick={() =>
                                handleCopyReference(
                                  convertDateToTexte(item.get_calendrier.jour),
                                  item.id
                                )
                              }
                            >
                              <i className="bi bi-clipboard2-fill"></i> Ref
                            </Button>
                          </div>
                        )}
                      />
                    )}
                  </DataTable>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
