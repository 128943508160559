import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../include/layout";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import { afficherMessagesSuccess } from "../../../../utils/helpers";
import ModesPaiementsService from "../../../../services/ModesPaiementsService";
import EditFormTProcedures from "../../../../components/EditFormTProcedures";

export default function ModesPaiementsEdit() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const location = useLocation()
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [editData, setEditData] = useState({})

    ///Save form data
    const handleUpdate = async (formData) => {

        await ModesPaiementsService.update(location.state.ref, formData, token).then((response) => {
            if (response.data.status != true) {
                Swal.fire({
                    icon: "error",
                    text: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                setValidationError(response.data.message)
            } else {
                //redirection sur index
                navigate(myroutes.modespaiements_index)
                afficherMessagesSuccess("Modification effectuée avec succès")
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message
            })
            //affichage des erreurs sur le formulaire
            setValidationError(error.erreur)
        });
    }

    const edit = async () => {
        try {
            const response = await ModesPaiementsService.edit(location.state.ref, token);
            //console.log("API response", response);
            if (response.data) {
                const data = response.data.data;
                // console.log("data", data);
                setEditData(data);
            } else {
                console.log("Data not found in API response");
                // Gérer le cas où response.data ou response.data.statut est undefined ou null
            }
        } catch (error) {
            console.error("API error", error);
            Swal.fire({
                icon: "error",
                text: error.message
            });
            setValidationError(error.erreur);
        }
    };





    useEffect(() => {
        edit();
        $("#parametres-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <BreadcrumbComponent params={{ isParametre: true, libelle: 'Modes de paiements', code: 'code' }} routeName={myroutes.modespaiements_index}></BreadcrumbComponent>
                        {/**Appel du formulaire de edit */}
                        <EditFormTProcedures onSubmit={handleUpdate} routeBack={myroutes.modespaiements_index} editData={editData}></EditFormTProcedures>

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}