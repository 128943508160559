// Utiliser les icone font-awesome
///Object.freeze Rend l'objet immuable pour garantir que les propriétés ne soient pas modifiées à runtime
const ButtonType = Object.freeze({
  EDIT: {
    color: "primary",
    icon: "fa-pencil-alt",
    text: "Modifier",
  },
  CREATE: {
    color: "success",
    icon: "fa-plus-circle",
    text: "Créer",
  },
  SHOW: {
    color: "info",
    icon: "fa-eye",
    text: "Voir",
  },
  DELETE: {
    color: "danger",
    icon: "fa-trash",
    text: "Supprimer",
  },
  BADGE: {
    color: "warning",
    icon: "fa-star",
    text: "Badge",
  },
  STATUT: {
    color: "secondary",
    icon: "fa-check",
    text: "Statut",
  },
  HELP: {
    color: "info",
    icon: "fa-info-circle",
    text: "Détails sur l'action",
  },
  EXPORT: {
    color: "danger",
    icon: "fa-file-export",
    text: "Exporter",
  },
  REMOVE: {
    color: "danger",
    icon: "fa-xmark-circle",
    text: "Désactiver",
  },
  DEFAULT: {
    color: "light",
    icon: "fa-question-circle",
    text: "Action inconnue",
  },
});

export default ButtonType;
