import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../include/layout";
import $ from "jquery";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import { setDataTable } from "../../../../utils/helpers";
import HistoriqueActionsService from "../../../../services/HistoriqueActionsService";
import UtilisateursService from "../../../../services/UtilisateursService";
import moment from "moment";
import parse from "html-react-parser"

export default function HistoriqueActionsIndex() {
  //##########################/// METHODES ##########################///
  const [historiqueactions, setHistoriqueactions] = useState([]);
  const [utilisateurs, setUtilisateurs] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  //get liste Historiques des actions
  const fetchHistoriquesactions = async (page) => {

    try {
      const response = await HistoriqueActionsService.index(token, page);
      const { data, pagination } = response.data;

      if (response.status === 200) {
        //console.log(data.data);

        setHistoriqueactions(data.data);
        setPagination(pagination);
      } else {
        console.error("Erreur dans la réponse:", data.message);
      }
    } catch (error) {
      console.error(error);
    }

    setDataTable();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const findFullnameById = (id) => {
    const findUser = utilisateurs.find(
      (item) => parseInt(item.id) === parseInt(id)
    );
    return findUser ? findUser.nom + " " + findUser.prenoms : "";
  };

  //get liste utilisaturs
  const fetchUtilisateurs = async () => {
    try {
      const response = await UtilisateursService.index(token);
      const responseData = response.data;

      if (responseData.data && responseData.data.length > 0) {
        setUtilisateurs(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };


  
  useEffect(() => {
    fetchHistoriquesactions(currentPage);
    fetchUtilisateurs();
    $("#parametres-nav").addClass("show");
  }, [currentPage]);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <BreadcrumbComponent
              params={{ isParametre: true, libelle: "Historique des actions" }}
              routeName={myroutes.historiqueactions_index}
            ></BreadcrumbComponent>

            {/**<div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h3 className="app-page-title mb-0">Liste des types de documents</h3>
                            </div>
                        </div> */}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font  table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Opération</th>
                        <th scope="col">Model</th>
                        <th scope="col">Id</th>
                        <th scope="col">Description</th>
                        <th scope="col">Date</th>
                        <th scope="col">Auteur</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {historiqueactions.length > 0 &&
                        historiqueactions.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td>
                              {item.action.toUpperCase()}
                            </td>
                            <td>{item.model_type}</td>
                            <td>{item.model_id}</td>
                            <td>{item.description && parse(item.description)}</td>
                            <td>
                              {moment(item.created_at).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </td>
                            <td>
                              {item.created_by
                                ? findFullnameById(item.created_by)
                                : "Utilisateur non trouvé"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end my-3">
                    <button
                      className="btn btn-md btn-secondary"
                      onClick={() =>
                        handlePageChange(
                          pagination.prev_page_url
                            ? pagination.current_page - 1
                            : pagination.current_page
                        )
                      }
                      disabled={!pagination.prev_page_url}
                    >
                      Page{" "}
                      {pagination.prev_page_url
                        ? pagination.current_page - 1
                        : pagination.current_page}
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-md btn-success"
                      onClick={() =>
                        handlePageChange(
                          pagination.next_page_url
                            ? pagination.current_page + 1
                            : pagination.current_page
                        )
                      }
                      disabled={!pagination.next_page_url}
                    >
                      Page {pagination.current_page}
                    </button>
                  </div>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
