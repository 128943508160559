import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery";
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import AssujettiesService from "../../../services/AssujettiesService";
import CivilitesService from "../../../services/CivilitesService";
import {
  afficherMessagesErreur,
  afficherMessagesSuccess,
  afficherOneMessageError,
  setDataTable,
} from "../../../utils/helpers";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { getButtonConfig } from "../../../utils/ButtonUtils";
import parse from "html-react-parser";
import ExcelExport from "../../../components/ExcelExport";

export default function AssujettiesIndex() {
  //##########################/// METHODES ##########################///
  const [statuts, setStatuts] = useState([]);
  const [assujetties, setAssujetties] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [visible, setVisible] = useState(false);
  const [assujettiesToExport, setAssujettiesToExport] = useState([]);
  const [civilites, setCivilites] = useState([]);
  const [file, setFile] = useState("");
  const [loadingImport, setLoadingImport] = useState(false);
  const isFirstRender = useRef(true);

  const getStatuts = (statut_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Statut = statuts.find((item) => {
      return item.id == idToFind;
    });

    return Statut ? Statut.libelle : "--";
  };

  const getCivilites = (civilite_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(civilite_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Civilite = civilites.find((item) => {
      return item.id == idToFind;
    });

    return Civilite ? Civilite.libelle : "--";
  };

  //get liste assujetties
  const fetchAssujetties = async () => {
    try {
      const response = await AssujettiesService.index(token);
      const responseData = response.data;
      // console.log("responseData", responseData)
      if (responseData.data && responseData.data.length > 0) {
        setAssujetties(responseData.data);

        setAssujettiesToExport(
          responseData.data.map((item, index) => {
            return {
              ID: ++index,
              "Dénomisation sociale": item.raison_sociale,
              "Acteur de justice": item.is_acteur ? "OUI" : "NON",
              Civilite: item.get_civilite?.libelle,
              Nom: item.nom,
              Prenoms: item.prenoms,
              Telephone: item.telephone,
              "Telephone primaire": item.telephone_primaire,
              "Telephone secondaire": item.telephone_sec,
              Email: item.email,
              "Email pro": item.email_pro,
              "Email secondaire": item.email_sec,
              "Type de personne": item.type_personne,
              Adresse: item.adresse && parse(item.adresse),
              RCCM: item.rccm,
              IFU: item.ifu,
              "Statut juridique": item.get_statut_juridique?.libelle,
              "Type d'acteur": item.get_type_acteur?.libelle,
            };
          })
        );
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  //redirection sur la page edit sans afficher les id dans l'url
  const goToEdit = (param) => {
    navigate(myroutes.assujetties_edit, { state: { ref: param } });
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        AssujettiesService.delete(param, token)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.message,
            });
            console.log(e);
          });
      }
    });
  };

  //get liste statuts
  const fetchStatuts = async () => {
    try {
      const response = await StatutsService.index(token);
      const statutsData = response.data.data;
      setStatuts(statutsData);
    } catch (error) {
      console.error("Erreur lors de la récupération  :", error);
    }
  };

  //get liste civilites
  const fetchCivilites = async () => {
    await CivilitesService.index(token)
      .then((response) => {
        setCivilites(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fonction de gestion du changement de fichier
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    setLoadingImport(true);

    try {
      const response = await AssujettiesService.importExcel(formData, token);
      if (response.status === 200) {
        setLoadingImport(false);
        fetchAssujetties();

        afficherMessagesSuccess(
          response.data.message ||
            "Bravo ! Votre enregistrement a été effectué avec succès."
        );
      } else {
        setLoadingImport(false);
        afficherOneMessageError(
          response.data.message ||
            "Une erreur s'est produite lors de l'importation."
        );
      }
    } catch (error) {
      //console.error("Une erreur s'est produite :", error);
      setLoadingImport(false);

      afficherMessagesErreur(error.response.data);
    }
  };

  useEffect(() => {
    //if (isFirstRender.current) {
    //isFirstRender.current = false;
    fetchAssujetties();
    fetchStatuts();
    fetchCivilites();
    //}

    $("#submenu-4").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">Assujetties </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  <div className="d-flex align-items-center ms-2 gap-2">
                    Liste des assujetties et conseils{" "}
                    <Link
                      className="btn btn-success btn-sm"
                      to={myroutes.assujetties_create}
                      title="Ajouter"
                    >
                      <i
                        className={`fa ${getButtonConfig("CREATE")?.icon}`}
                      ></i>{" "}
                      Ajouter
                    </Link>
                    <ExcelExport
                      fileName={"assujetties"}
                      data={assujettiesToExport}
                    />
                    <div className="dropdown">
                      <button
                        className="btn btn-sm btn-success dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-upload"></i>&nbsp;Importer
                      </button>

                      <Form
                        className="dropdown-menu mb-3 p-4"
                        style={{ minWidth: "400px" }}
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="d-flex align-items-center">
                          <input
                            type="file"
                            className="form-control border-success w-100"
                            accept=".xlsx, .xls"
                            name="file"
                            onChange={handleFileChange}
                            required
                          />
                          <ButtonWithSpinner
                            type="submit"
                            loading={loadingImport}
                            color={"success"}
                            title="Importer les assujetties"
                            className="ms-2"
                          >
                            <i className="bi bi-upload"></i>&nbsp;OK
                          </ButtonWithSpinner>
                        </div>
                      </Form>
                    </div>

                  </div>
                </h3>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Raison Sociale </th>
                        <th className="">Nom & Prénom (s)</th>
                        <th className="">Email</th>
                        <th className="">Adresse</th>
                        <th className="">Téléphone</th>
                        <th className="">RCCM</th>
                        <th className="">IFU</th>
                        <th className="">AC (Oui/Non)</th>
                        <th className="">Statut</th>
                        <th className="">Date modification</th>
                        <th className="">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {assujetties.length > 0 &&
                        assujetties.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell">{item.raison_sociale}</td>
                            <td className="cell">
                              <span className="fw-bold">
                                {getCivilites(item.civilite_id)}
                              </span>{" "}
                              {item.nom} {item.prenoms}
                            </td>
                            <td className="cell">{item.email}</td>
                            <td className="cell">{item.adresse}</td>
                            <td className="cell">{item.telephone}</td>
                            <td className="cell">{item.rccm}</td>
                            <td className="cell">{item.ifu}</td>
                            <td className="cell">
                              {item.is_acteur === 1
                                ? "oui - " + item.get_type_acteur?.libelle
                                : "non"}
                            </td>
                            <td className="cell">
                              {item.get_statut && item.get_statut.libelle ? (
                                <span
                                  className={`badge ${
                                    getStatuts(item.statut_id) === "ACTIF"
                                      ? "bg-success"
                                      : "bg-danger"
                                  }`}
                                >
                                  {getStatuts(item.statut_id)}
                                </span>
                              ) : (
                                <span className="badge bg-secondary">N/A</span>
                              )}
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.updated_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="cell">
                              <div className="d-flex">
                                <Button
                                  className={`btn btn-sm btn-${
                                    getButtonConfig("EDIT")?.color
                                  }`}
                                  onClick={() => goToEdit(item.ref)}
                                  title={`${getButtonConfig("EDIT")?.text}`}
                                >
                                  <i
                                    className={`fa ${
                                      getButtonConfig("EDIT")?.icon
                                    }`}
                                  ></i>
                                </Button>{" "}
                                &nbsp;&nbsp;
                                <Button
                                  className={`btn btn-sm btn-${
                                    getButtonConfig("DELETE")?.color
                                  }`}
                                  onClick={() => onDelete(item.ref)}
                                  title={`${getButtonConfig("DELETE")?.text}`}
                                >
                                  <i
                                    className={`fa ${
                                      getButtonConfig("DELETE")?.icon
                                    }`}
                                  ></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
