import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DatesaudiencesService from "../services/DatesaudiencesService";
import Layout from "./admin/include/layout";
import { convertDateToTexte } from "../utils/helpers";
import { Button, Col, Form, Row } from "react-bootstrap";
import ChambresService from "../services/ChambresService";
import CalendriersGlobalsService from "../services/CalendriersGlobalsService";
import ExcelExport from "../components/ExcelExport";
import moment from "moment";
import Swal from "sweetalert2";
//import parse from "html-react-parser";


export default function TableaudebordStats() {
  const token = useSelector((state) => state.auth.token);
  const [datesaudiences, setDatesaudiences] = useState([]);
  const [filteredDatesaudiences, setFilteredDatesaudiences] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [date_debut, setDateDebut] = useState("");
  const [date_fin, setDateFin] = useState("");
  const [chambre_id, setChambre_id] = useState('')
  const [chambres, setChambres] = useState([])
  const [datesAudiencesToExport, setDatesAudiencesToExport] = useState([]);


  // Fonction pour récupérer les dates d'audiences
  const fetchDatesaudiences = async () => {
    try {
      const response = await DatesaudiencesService.groupedDates(token);
      const responseData = response.data;

      if (responseData.data && responseData.data.length > 0) {
        setDatesAudiencesToExport(
                  responseData.data.map((item) => {
                    return {
                      "DATES AUDIENCES": item.date_audience,
                      "DOSSIER AU ROLE": item.nombre_dossiers,
                      "DOSSIERS VIDES": item.vide,
                      "NOUVEAUX DOSSIERS": item.nouveaux,
                      "EN DELIBERE": item.delibere,
                      "DOSSIERS RADIÉS": item.radie,
                      "DOSSIERS RENVOYÉS": item.renvoye
                    };
                  })
                );
        setDatesaudiences(responseData.data);
        setFilteredDatesaudiences(responseData.data);
        //console.log("DatesAudiences", responseData.data)
        //const totalRecords = responseData.data.length; 
        setTotalRecords(totalRecords);
        //console.log('totalRecords records', totalRecords);

      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  //get liste chambres
  const fetchChambres = async () => {
    await ChambresService.index(token)
      .then((response) => {
        setChambres(response.data.data);
        //console.log('chambres', response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fonction pour récupérer le calendrier et calculer les dates de début et de fin
  const calendrier = async () => {
    try {
      const response = await CalendriersGlobalsService.index(token);
      const resultat = response.data.data;
      //console.log('resultat', resultat);

      if (resultat && resultat.length > 0) {

        let minDate = new Date(resultat[0].date_debut);
        let maxDate = new Date(resultat[0].date_fin);


        resultat.forEach(item => {
          const dateDebut = new Date(item.date_debut);
          const dateFin = new Date(item.date_fin);

          if (dateDebut < minDate) {
            minDate = dateDebut;
          }

          if (dateFin > maxDate) {
            maxDate = dateFin;
          }
        });

        // Mettre à jour les états startDate et endDate
        setStartDate(minDate);
        setEndDate(maxDate);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de calendrier:", error);
    }
  };


  //Filtrer le tableau de bord

  // Gestion du filtrage
  const handleSubmit = async () => {
    if (!date_debut || !date_fin || !chambre_id) {
      Swal.fire({
        icon: "Error",
        title: "Formulaire mal remplit",
        text: 'Veuillez remplir les champs du formulaire s\'il vous plaît',
      });
      return;
    }

    try {
      const response = await DatesaudiencesService.filtrerDossier({
        date_debut: date_debut,
        date_fin: date_fin,
        chambre_id: chambre_id
      }, token);

      if (response.data.success) {
        const filteredData = response.data.data;
        // Mettez à jour les données filtrées dans le tableau
        setFilteredDatesaudiences(filteredData);
        setTotalRecords(filteredData.length); 
        setDatesAudiencesToExport(filteredData.map((item) => ({
          "DATES AUDIENCES": item.date_audience,
          "DOSSIER AU ROLE": item.nombre_dossiers,
          "DOSSIERS VIDES": item.vide,
          "NOUVEAUX DOSSIERS": item.nouveaux,
          "EN DELIBERE": item.delibere,
          "DOSSIERS RADIÉS": item.radie,
          "DOSSIERS RENVOYÉS": item.renvoye
        }))); 

        setStartDate(date_debut);
        setEndDate(date_fin);
      } else {
        console.log('Aucune donnée trouvée pour ces critères');
      }
    } catch (error) {
      console.error("Erreur lors du filtrage:", error);
    }
  };

  // Fonction pour gérer le changement de page
  const onPageChange = (event) => {
    setCurrentPage(event.page);
    setItemsPerPage(event.rows);
  };

  useEffect(() => {
    fetchDatesaudiences();
    calendrier();
    fetchChambres();
  }, []);

  const getPagedData = () => {
    const first = currentPage * itemsPerPage;
    const last = first + itemsPerPage;
    //console.log("Slicing data from:", first, "to:", last);
    //console.log('datesAudiences',datesaudiences);
    return filteredDatesaudiences.slice(first, last);
    
  };

  return (
    <Layout>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Tableau de bord</h1>
        </div>

        <div
          className="card bg-warningx pt-1 px-3"
          style={{
            color: "#264f52",
            backgroundColor: "#3b7d821e",
            border: "1px solid white",
          }}
        >
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {/* Titre */}
            <h6 className="mb-0 me-3">
              <i className="bi bi-menu-button-wide-fill"></i> Filtrer le tableau
            </h6>

            {/* Formulaire */}
            <Form className="d-flex align-items-center gap-3 flex-wrap">
              {/* Champ Date de début */}
              <div className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Date de début <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id="date_debut"
                    name="date_debut"
                    value={date_debut}
                    onChange={(e) => setDateDebut(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>

              {/* Champ Date de fin */}
              <div className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Date de fin <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id="date_fin"
                    name="date_fin"
                    value={date_fin}
                    onChange={(e) => setDateFin(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>

              {/* Dropdown pour chambres */}
              <div className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Chambre <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Select
                    id="chambre_id"
                    name="chambre_id"
                    value={chambre_id}
                    required
                    onChange={(e) => setChambre_id(e.target.value)}
                  >
                    <option value="">-- Sélectionnez --</option>
                    {Array.isArray(chambres) && chambres.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.libelle}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              {/* Bouton Filtrer */}
              <div className="form-group">
                <Form.Group className="mb-3">
                  <Button variant="primary" className="mt-4"
                    onClick={handleSubmit}>
                    Filtrer
                  </Button>
                </Form.Group>
              </div>
            </Form>
            <ExcelExport
                      fileName={"Tableau de bord"}
                      data={datesAudiencesToExport}
                    />
          </div>
        </div>



        <div className="app-card app-card-orders-table shadow-lg mb-5">
          <h3 style={{ textAlign: 'center', paddingTop: '10px' }}>
            Tableau de bord du{" "}
            {startDate && endDate ? (
              <span>{convertDateToTexte(startDate)} - {convertDateToTexte(endDate)}</span>
            ) : (
              <span>Chargement des dates...</span>
            )}
          </h3>
          <div className="app-card-body mx-3 py-2">
            <div className="table-responsive mt-4">
              <DataTable
                value={getPagedData()}
                paginator
                rows={itemsPerPage}
                totalRecords={totalRecords}  
                onPage={onPageChange}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
                stripedRows
              >
                <Column field="date_audience" header="DATES D'AUDIENCES" body={(rowData) => convertDateToTexte(rowData.date_audience)} />
                <Column field="nombre_dossiers" header="DOSSIERS AU ROLE" />
                <Column field="vide" header="DOSSIERS VIDES" />
                <Column field="nouveaux" header="NOUVEAUX DOSSIERS" />
                <Column field="delibere" header="DOSSIERS EN DELIBERE" />
                <Column field="radie" header="DOSSIERS RADIÉS" />
                <Column field="renvoye" header="DOSSIERS RENVOYÉS" />
              </DataTable>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
