import http from "./http"

class AdministrationService {
    clear_cache(token){
        return http.get("/api/v01/web/ClearCache",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    active_storage(token){
        return http.get("/api/v01/web/ActiveStorage",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    historique_connexions(token){
        return http.get("/api/v01/web/historiquesconnexions/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    downloadFile(data, token){
        return http.post(`/api/v01/web/files/download`, data, { headers: { 'Authorization': 'Bearer '+token }, responseType: "blob" })
    }

    changeStatut(ref, table, statut, token){
        return http.get(`/api/v01/web/update/${ref}/${table}/${statut}`,{ headers: { 'Authorization': 'Bearer '+token } })
    }


}

export default new AdministrationService()