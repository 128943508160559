import { Link } from "react-router-dom";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Layout from "../../include/layout";
import $ from "jquery";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import {
  afficherMessagesSuccess,
  afficherOneMessageError,
  copyToClipboard,
  setDataTable,
} from "../../../../utils/helpers";
import moment from "moment";
import JoursFeriesService from "../../../../services/JoursFeriesService";
import { Button, Col, Form, Row } from "react-bootstrap";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import Swal from "sweetalert2";
import { getButtonConfig } from "../../../../utils/ButtonUtils";
import { Dialog } from "primereact/dialog";

export default function JoursFeriesIndex() {
  //##########################/// METHODES ##########################///
  const [joursferies, setJoursferies] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const [file, setFile] = useState("");

  const [loading, setLoading] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [editRef, setEditRef] = useState("");
  const [formData, setFormData] = useState({});

  const fetchJoursFeries = async () => {
    try {
      const response = await JoursFeriesService.index(token);
      const data = response.data;

      if (response.status === 200) {
        setJoursferies(data?.data);
      } else {
        console.error("Erreur dans la réponse:", data.message);
      }
    } catch (error) {
      console.error(error);
    }

    setDataTable();
  };

  // Fonction de gestion du changement de fichier
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  ///Save form data
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);

    try {
      const response = await JoursFeriesService.importExcel(formData, token);
      //console.log(response)
      if (response.status === 200) {
        setLoading(false);
        fetchJoursFeries();

        afficherMessagesSuccess(
          response.data.message ||
            "Bravo ! Votre enregistrement a été effectué avec succès."
        );

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLoading(false);
        afficherOneMessageError(
          response.data.message ||
            "Une erreur s'est produite lors de l'importation."
        );
      }
    } catch (error) {
      console.error("Une erreur s'est produite :", error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        text: "Une erreur s'est produite lors de l'importation.",
        timer: 3000,
      });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  ///Save form data
  const handleAddSave = async (e) => {
    e.preventDefault();
    setShowAddForm(false);
    await JoursFeriesService.store(formData, token)
      .then((response) => {
        if (response.data.status == true) {
          fetchJoursFeries();
          afficherMessagesSuccess(
            "Bravo ! Votre enregistrement a été efectué avec succès. "
          );
        } else {
          afficherOneMessageError(
            response.data.message + " " + response.data.data
          );
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
        console.log(error);
      });
  };

  const lauchEditForm = async (ref) => {
    //Lancer le modal d'edition
    try {
      const response = await JoursFeriesService.edit(ref, token);
      if (response.data) {
        const data = response.data.data;
        setEditData(data);
        setEditRef(ref);
        setShowEditForm(true);
      } else {
        console.log("Data not found in API response");
      }
    } catch (error) {
      console.error("API error", error);
      Swal.fire({
        icon: "error",
        text: error.message,
      });
      //setValidationError(error.erreur);
    }
  };

  ///Save form data
  const handleUpdate = async (e) => {
    e.preventDefault();
    setShowEditForm(false);
    await JoursFeriesService.update(editRef, editData, token)
      .then((response) => {
        if (response.data.status != true) {
          fetchJoursFeries();
          Swal.fire({
            icon: "error",
            text: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          //setValidationError(response.data.message)
        } else {
          //Actualiser les data
          fetchJoursFeries();
          afficherMessagesSuccess("Modification effectuée avec succès");
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
      });
  };

  const handleCopyReference = (reference, itemId) => {
    copyToClipboard(reference);
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        JoursFeriesService.delete(param, token)
          .then((response) => {
            if (response.data.erreur) {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.message,
            });
            console.log(e);
          });
      }
    });
  };

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    fetchJoursFeries();
    $("#parametres-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <BreadcrumbComponent
              params={{
                isParametre: true,
                libelle: "Jours feriés",
              }}
              routeName={myroutes.joursferies_index}
            ></BreadcrumbComponent>

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Jours feriés{" "}
                  <Link
                    className={`btn btn-sm btn-${
                      getButtonConfig("CREATE")?.color
                    }`}
                    onClick={() => setShowAddForm(true)}
                    title="Ajouter"
                  >
                    <i className={`fa ${getButtonConfig("CREATE")?.icon}`}></i>
                    &nbsp;Ajouter
                  </Link>{" "}
                </h3>
              </div>
              <div className="col-auto">
                <Form
                  className="settings-form mb-3"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="d-flex align-items-end">
                    <input
                      type="file"
                      className="form-control border-success"
                      accept=".xlsx, .xls"
                      name="file"
                      onChange={handleFileChange}
                      required
                    />
                    <ButtonWithSpinner
                      type="submit"
                      loading={loading}
                      color={"success"}
                      title="Importer la liste des jours feriés"
                      className="ms-2"
                    >
                      <i className="bi bi-upload"></i>&nbsp;Importer
                    </ButtonWithSpinner>
                  </div>
                </Form>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font  table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Libelle</th>
                        <th scope="col">Date</th>
                        <th scope="col">Statut</th>
                        <th>Date modification</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {joursferies.length > 0 &&
                        joursferies.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td>{item.libelle}</td>
                            <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                            <td>
                              <span
                                className={`badge ${
                                  item.get_statut?.libelle === "ACTIF"
                                    ? "text-bg-success"
                                    : "text-bg-danger"
                                }`}
                              >
                                {item.get_statut?.libelle}
                              </span>
                            </td>
                            <td>
                              {moment(item.updated_at).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </td>
                            <td>
                            <div className="d-flex align-items-center gap-1">
                              <Button
                                className={`btn btn-sm btn-${
                                  getButtonConfig("EDIT")?.color
                                }`}
                                onClick={() => lauchEditForm(item.ref)}
                                title="Modifier"
                              >
                                <i
                                  className={`fa ${
                                    getButtonConfig("EDIT")?.icon
                                  }`}
                                ></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className={`btn btn-sm btn-${
                                  getButtonConfig("DELETE")?.color
                                }`}
                                onClick={() => onDelete(item.ref)}
                                title="Supprimer"
                              >
                                <i
                                  className={`fa ${
                                    getButtonConfig("DELETE")?.icon
                                  }`}
                                ></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handleCopyReference(
                                    item.libelle + " " + item.date,
                                    item.id
                                  )
                                }
                              >
                                <i className="bi bi-clipboard2-fill"></i> Ref
                              </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/****Mode d'ajout */}
            <Dialog
              header="Enregistrer un nouveau jour ferié"
              visible={showAddForm}
              onHide={() => setShowAddForm(false)}
            >
              <div className="app-card-body py-3">
                <Form
                  className="mx-3 settings-form"
                  style={{ textAlign: "left" }}
                  onSubmit={handleAddSave}
                >
                  <Row>
                    <Col md={6} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Libelle <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={handleChange}
                          value={formData.libelle}
                          placeholder=""
                          name="libelle"
                          aria-label="libelle"
                          maxLength={100}
                          minLength={2}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Date <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={handleChange}
                          value={formData.date}
                          placeholder=""
                          name="date"
                          aria-label="date"
                          type="date"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button type="submit" variant="warning me-3">
                    Enregistrer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowAddForm(false)}
                  >
                    Annuler
                  </Button>
                </Form>
              </div>
            </Dialog>

            {/****Mode d'edition */}
            <Dialog
              header="Modifier un jour ferié"
              visible={showEditForm}
              onHide={() => setShowEditForm(false)}
            >
              <div className="app-card-body py-3">
                <Form
                  className="mx-3 settings-form"
                  style={{ textAlign: "left" }}
                  onSubmit={handleUpdate}
                >
                  <Row>
                    <Col md={6} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Libelle <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={handleEditChange}
                          value={editData.libelle}
                          placeholder=""
                          name="libelle"
                          aria-label="libelle"
                          maxLength={100}
                          minLength={2}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Date <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={handleEditChange}
                          value={editData.date}
                          placeholder=""
                          name="date"
                          aria-label="date"
                          type="date"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button type="submit" variant="warning me-3">
                    Enregistrer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditForm(false)}
                  >
                    Annuler
                  </Button>
                </Form>
              </div>
            </Dialog>

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
