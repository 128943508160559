import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import AssujettiesService from "../services/AssujettiesService";
import { useSelector } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  afficherMessagesErreur,
  afficherMessagesSuccess,
  afficherOneMessageError,
  convertDateToTexte,
  getColorStatus,
} from "../utils/helpers";
import ChambresService from "../services/ChambresService";
import ChambresmembresService from "../services/ChambresmembresService";
import { Dialog } from "primereact/dialog";
import TypespartiesService from "../services/TypespartiesService";
import Swal from "sweetalert2";
import PartiesdossiersService from "../services/PartiesdossiersService";
import { Controller } from "react-hook-form";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { Dropdown } from "primereact/dropdown";
import { FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import { getButtonConfig } from "../utils/ButtonUtils";
import JonctionsService from "../services/JonctionsService";
import { Link } from "react-router-dom";
import { myroutes } from "../routes/webroute";

///## Schema de validation
const schema = Joi.object({
  assujettie_id: Joi.string().required().messages({
    "string.empty": "Veuillez sélectionner un assujettie",
  }),
  /*type_partie_id: Joi.string().required().messages({
    "string.empty": "Veuillez sélectionner le type de partie",
  }),
  is_avocat: Joi.boolean().required().messages({
    "string.empty": "Le champ Avocat est obligatoire",
  }),*/
});

const TabResumeDossier = ({ dossier }) => {
  const token = useSelector((state) => state.auth.token);
  const [assujetties, setAssujetties] = useState([]);
  const [allassujetties, setAllAssujetties] = useState([]);
  const [chambres, setChambres] = useState([]);
  const [chambres_membres, setChambresMembres] = useState([]);
  const profils = useSelector((state) => state.auth.profil);

  const [showAddPartieModal, setShowAddPartieModal] = useState(false);
  const handleCloseModal = () => setShowAddPartieModal(false);

  const [typesparties, setTypesparties] = useState([]);

  // Documents fixes
  const initialData = [
    {
      id: 1,
      label: "Appelant(s)",
      parties: [""],
      conseils: [""],
    },
    {
      id: 2,
      label: "Intimé(s)",
      parties: [""],
      conseils: [""],
    },
  ];

  const [docData, setDocData] = useState(initialData);
  const [formData, setFormData] = useState({});

  // Ajouter une ligne dans une colonne spécifique
  const addLine = (column, docId) => {
    setDocData((prevDocData) =>
      prevDocData.map((doc) =>
        doc.id === docId
          ? {
              ...doc,
              [column]: [...(doc[column] || []), ""], // Ajoute un champ vide
            }
          : doc
      )
    );
  };

  // Supprimer une ligne dans une colonne spécifique
  const removeLine = (column, docId, index) => {
    setDocData((prevDocData) =>
      prevDocData.map((doc) =>
        doc.id === docId
          ? {
              ...doc,
              [column]: doc[column].filter((_, i) => i !== index), // Supprime à l'index
            }
          : doc
      )
    );
  };

  //get liste partiesprofils
  const fetchTypesparties = async () => {
    try {
      const response = await TypespartiesService.index(token);
      const typespartiesData = response.data.data;
      setTypesparties(typespartiesData);
    } catch (error) {
      console.error("Erreur lors de la récupération des types profils:", error);
    }
  };

  //get liste assujetties
  const fetchAssujetties = async () => {
    try {
      const response = await AssujettiesService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        // Combiner les deux étapes de mappage et de filtrage
        const alldata = responseData.data.map((item) => {
          // Crée un objet générique pour toutes les assujetties
          const assujettie = {
            name: item.raison_sociale ? item.raison_sociale : item.nom,
            id: item.id,
          };

          // Si c'est un avocat, on l'ajoute aussi aux acteurs (assujetties_acteurs)
          if (item.get_type_acteur?.libelle === "Avocat") {
            setAssujetties_acteurs((prev) => [...prev, assujettie]);
          }

          return assujettie;
        });

        // Définir tous les assujetties
        setAllAssujetties(alldata);

        // Filtrer les assujetties liées au dossier
        const assujettiesFiltres = responseData.data.filter(
          (assujetti) =>
            dossier &&
            dossier.get_partie &&
            dossier.get_partie.length > 0 &&
            dossier.get_partie.some(
              (partie) =>
                partie.get_assujetie &&
                partie.get_assujetie.ref === assujetti.ref
            )
        );
        setAssujetties(assujettiesFiltres);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };
  //ORdonner et retourner celle ayant l'id la plus élevé
  const sortedData = (data) => {
    if (data.length > 0) {
      //const sortedData = [...data].sort((a, b) => b.id - a.id);
      const itemWithMaxId = data.reduce(
        (max, item) => (item.id > max.id ? item : max),
        data[0]
      );
      //console.log(itemWithMaxId.get_calendrier.jour)
      return itemWithMaxId.get_calendrier;
    }
  };

  //get liste chambres
  const fetchChambres = async () => {
    await ChambresService.index(token)
      .then((res) => {
        setChambres(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChambre = (chambreId) => {
    let chambre = chambres.find((opt) => parseInt(opt.id) === chambreId);
    //console.log(chambreId)
    if (chambre) return chambre.libelle;
  };

  //get liste membres chambres
  const fetchChambresMembres = async () => {
    await ChambresmembresService.index(token)
      .then((res) => {
        setChambresMembres(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Retourner les membres distincts d'une chambre à partir de son ID
  const getActeurChambre = (chambreId) => {
    // Filtrer les membres pour ne garder que ceux qui ont le même ID de chambre
    let membres = chambres_membres.filter(
      (opt) => parseInt(opt.chambre_id) === chambreId
    );
    // Si aucun membre n'est trouvé, retourner "Pas de membre"
    if (membres.length === 0) {
      return null;
    }
    // Créer un tableau pour stocker les noms des membres distincts
    let nomsMembres = [];

    let countMembre = new Set();

    // Parcourir les membres filtrés
    membres.forEach((membre) => {
      // Vérifier si le nom complet du membre n'a pas déjà été ajouté à la liste
      let nomComplet = `${membre.get_utilisateur.nom} ${membre.get_utilisateur.prenoms}`;
      if (!countMembre.has(membre.utilisateur_id)) {
        // Si l'id de l'utilisateur n'est pas déjà dans la liste, l'ajouter
        nomsMembres.push(nomComplet);
        countMembre.add(membre.utilisateur_id);
      }

      /*if (!nomsMembres.includes(nomComplet)) {
              // Si le nom complet n'est pas déjà dans la liste, l'ajouter
              nomsMembres.push(nomComplet);
            }*/
    });
    //console.log(countMembre.size);

    // Retourner les noms des membres distincts sous forme de chaîne séparée par des virgules
    return nomsMembres.join(", ");
  };

  const handleShowAddPartie = () => {
    setShowAddPartieModal(true);
  };

  const handleSaveParties = async () => {
    //console.log(formData);

    const partiesPayload = [];

    // Transformation des données
    Object.entries(formData).forEach(([key, value]) => {
      const [type, typePartieId] = key.split("-"); // Extrait "partie" ou "conseil" et le chiffre correspondant
      const isAvocat = type === "conseil"; // is_avocat est true si c'est "conseil"

      partiesPayload.push({
        assujettie_id: value.id,
        type_partie_id: parseInt(typePartieId, 10), // Convertit le type_partie_id en entier
        is_avocat: isAvocat,
      });
    });

    const payload = {
      dossier_id: dossier.id,
      parties: partiesPayload,
    };

    handleCloseModal();
    //console.log("Payload formaté :", payload);
    await PartiesdossiersService.storePartiesWithDossier(payload, token)
      .then((response) => {
        if (response.status === 200) {
          afficherMessagesSuccess(response.data?.message);
          // Actualiser la page
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        //afficherOneMessageError(error.response.data.message);
        //afficherMessagesErreur(error.response.data?.data);
        error.response && afficherOneMessageError(error.response.data?.data);
      });
  };

  ///### Evenement: Sélection de donnée sur le formulaire d'ajout ////########
  const handleChangePartie = (docId, index, value) => {
    //console.log(`Document ID: ${docId}, Index: ${index}, Value: ${value}`);

    setFormData((prev) => ({
      ...prev,
      [`partie-${docId}-${index}`]: value,
    }));
  };

  const handleChangeConseil = (docId, index, value) => {
    setFormData((prev) => ({
      ...prev,
      [`conseil-${docId}-${index}`]: value,
    }));
  };

  ///### End Evenement: Sélection de donnée sur le formulaire d'ajout ////########

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const [assujetties_acteurs, setAssujetties_acteurs] = useState([]);
  const [dossierFinal, setDossierFinal] = useState("");

  const findDossierFinal = async (token) => {    
    await JonctionsService.index(token)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data.data.find(
            (item) => item.dossier_source_id == dossier?.id
          );
          setDossierFinal(
            data &&
              data.get_dossier_destination 
          );
        } else {
          afficherMessagesErreur(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        //afficherMessagesErreur(error.response.data?.message);
      });
  };

  useEffect(() => {
    fetchAssujetties();
    fetchChambres();
    fetchChambresMembres();
    fetchTypesparties();
    findDossierFinal();
  }, []);

  return (
    <div>
      <div className="row g-3 mb-0  align-items-center justify-content-between">
        <div
          className="card bg-warningx pt-1"
          style={{
            color: "#1f84d6",
            backgroundColor: "#1f84d621",
            border: "1px solid white",
          }}
        >
          <h6 className="pt-1">
            <i className="bi bi-eye-fill me-2"></i>APERÇU DU DOSSIER
          </h6>
        </div>
      </div>
      <div className="container">
        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Numéro du dossier</p>
          </div>
          <div className="col-md-8">
            <p className="th-table"> {dossier && dossier.reference_dossier}</p>
          </div>
        </div>
        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Partie(s) et Conseil(s)</p>
          </div>
          <div className="col-md-8">
            <p className="th-table">
              {(profils.includes("SUPERADMIN") || profils.includes("GEC")) && (
                <>
                  <button
                    className={`btn btn-sm btn-${
                      getButtonConfig("CREATE")?.color
                    }`}
                    onClick={() => handleShowAddPartie()}
                    title="Ajouter les parties"
                    style={{ float: "right" }}
                  >
                    <i className="fa fa-plus-circle"></i>
                  </button>
                </>
              )}
              {dossier &&
                dossier.get_partie &&
                dossier.get_partie.length > 0 &&
                Object.values(
                  dossier.get_partie.reduce((acc, dossier) => {
                    const type_partie_id = dossier.type_partie_id;
                    if (!acc[type_partie_id]) {
                      acc[type_partie_id] = [];
                    }
                    acc[type_partie_id].push(dossier);
                    return acc;
                  }, {})
                ).map((group, idx) => (
                  <div key={idx}>
                    <span className="text-center">
                      {group[0].type_partie_id !== 1 && "C/"}
                    </span>
                    <ul>
                      {group.map((item, index) =>
                        item.is_avocat ? (
                          <span key={index}>
                            <b>(</b>
                            {item.get_assujetie.raison_sociale}
                            <b>)</b>
                          </span>
                        ) : (
                          <li key={index}>
                            {item.is_avocat ? (
                              <>
                                <b>(</b>
                                {item.get_assujetie.raison_sociale}
                                <b>)</b>
                              </>
                            ) : (
                              item.get_assujetie.raison_sociale
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                ))}
            </p>
          </div>
        </div>
        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Statut</p>
          </div>
          <div className="col-md-8">
            <p className="th-table">
              <span
                className="badge"
                style={{
                  backgroundColor: getColorStatus(
                    dossier &&
                      dossier.get_statut_dossier &&
                      dossier.get_statut_dossier.id
                  ),
                }}
              >
                {" "}
                {dossier &&
                  dossier.get_statut_dossier &&
                  dossier.get_statut_dossier.libelle}
              </span>
              {/*&nbsp;&nbsp;<Link
                className="btn btn-sm btn-info icon-font me-2 mb-2 "
                title="Consulter le dossier"
                to={`${myroutes.dossiers_show}/${dossierFinal?.ref}`}
              >
                <i className="bi bi-eye-fill text-white h6"></i>
              </Link> */}
            </p>
          </div>
        </div>
        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Date d'enrôlement</p>
          </div>
          <div className="col-md-8">
            <p className="th-table">
              {" "}
              {dossier && dayjs(dossier.created_at).format("DD/MM/YYYY H:m:s")}
            </p>
          </div>
        </div>
        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Jugement attaqué et Provenance</p>
          </div>
          <div className="col-md-8">
            <p className="th-table">
              {`${
                dossier &&
                dossier.jugement_attaque +
                  " du " +
                  dayjs(dossier.date_jugement).format("DD/MM/YYYY") +
                  " au " +
                  dossier.get_tribunal?.libelle
              }`}
            </p>
          </div>
        </div>
        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Date d'audience</p>
          </div>
          <div className="col-md-8">
            <p className="th-table text-left">
              {dossier &&
                dossier.get_date_audience &&
                dossier.get_date_audience.length > 0 && (
                  <span>
                    {convertDateToTexte(
                      sortedData(dossier.get_date_audience).jour
                    )}
                  </span>
                )}
            </p>
          </div>
        </div>
        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Objet</p>
          </div>
          <div className="col-md-8">
            <p className="th-table" style={{}}>
              {" "}
              {dossier && dossier.get_objet && dossier.get_objet.libelle}
            </p>
          </div>
        </div>

        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Type de Procédure</p>
          </div>
          <div className="col-md-8">
            <p className="th-table">
              <span className="badge bg-secondary">
                {dossier && dossier.get_procedure.get_type_procedure.libelle}
              </span>
            </p>
          </div>
        </div>

        <div className="row border-ligne">
          <div className="col-md-4">
            <p className="th-table fw-bold">Chambre</p>
          </div>
          <div className="col-md-8">
            <p className="th-table">
              {dossier && dossier.get_date_audience && (
                <span>
                  {getChambre(sortedData(dossier.get_date_audience).chambre_id)}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
      <div
        className="container mt-3"
        style={{ display: profils.includes("AJ") ? "none" : "" }}
      >
        <div className="row">
          <div className="row g-3 mb-0  align-items-center justify-content-between">
            <div
              className="card bg-warningx pt-1"
              style={{
                color: "#1f84d6",
                backgroundColor: "#1f84d621",
                border: "1px solid white",
              }}
            >
              <h6 className="pt-1">
                <i className="fa fa-users me-2"></i>MEMBRES AUTORISES
              </h6>
            </div>
          </div>
          {dossier &&
            dossier.get_date_audience &&
            dossier.get_date_audience.length > 0 &&
            dossier.get_date_audience.map(
              (option, index) =>
                option.get_calendrier &&
                getActeurChambre(option.get_calendrier.chambre_id) !== null && (
                  <div key={index} className="row border-ligne mt-3">
                    <div className="col-md-11">
                      <div className="th-table d-flex">
                        <img
                          className="me-3"
                          src="/NiceAdmin/assets/img/images.png"
                          alt="user"
                          style={{ width: "28px", height: "28px" }}
                        />
                        <p className="th-tablex fw-boldx">
                          {getActeurChambre(option.get_calendrier.chambre_id)}
                        </p>
                      </div>
                    </div>
                  </div>
                )
            )}
        </div>
      </div>

      {/* Modal */}
      <Dialog
        header={"Ajouter les parties ou conseils"}
        visible={showAddPartieModal}
        maximizable
        onHide={handleCloseModal}
        style={{
          maxWidth: "900px",
          minHeight: "300px",
          color: "GrayText",
          borderRadius: "8px",
        }}
        headerStyle={{ background: "#f7f7f7", borderBottom: "1px solid #ccc" }}
      >
        <Form className="mx-3 settings-form">
          <div className="table-responsive mt-3">
            <table className="table align-middle">
              <thead
                style={{ backgroundColor: "#f8f9fa", textAlign: "center" }}
              >
                <tr>
                  <th style={{ width: "20%" }}></th>
                  <th style={{ width: "40%" }}>Parties</th>
                  <th
                    style={{ width: "40%", borderLeft: "2px solid #dee2e6" }}
                    className=""
                  >
                    Conseils
                  </th>
                </tr>
              </thead>
              <tbody>
                {docData.map((doc) => (
                  <tr key={doc.id}>
                    <td style={{ fontWeight: "bold", textAlign: "center" }}>
                      {doc.label}
                    </td>
                    {/* Parties */}
                    <td>
                      {(doc.parties || []).map((_, index) => (
                        <div
                          key={`partie-${doc.id}-${index}`}
                          className="d-flex align-items-center mb-2"
                        >
                          <Controller
                            name={`partie-${doc.id}-${index}`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                options={allassujetties} // Utilise les données des parties
                                optionLabel="name"
                                optionValue="id"
                                placeholder="Sélectionner une partie..."
                                filter
                                className="w-100"
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #ddd",
                                }}
                                onChange={(e) => {
                                  field.onChange(e.value); // Met à jour la valeur dans le formulaire
                                  handleChangePartie(doc.id, index, e.value); // gestion spécifique
                                }}
                              />
                            )}
                          />
                          <span className="ms-2">
                            {index === 0 ? (
                              <FaPlusCircle
                                color="#28a745"
                                size={20}
                                style={{ cursor: "pointer" }}
                                onClick={() => addLine("parties", doc.id)}
                              />
                            ) : (
                              <FaTimesCircle
                                color="#dc3545"
                                size={20}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  removeLine("parties", doc.id, index)
                                }
                              />
                            )}
                          </span>
                        </div>
                      ))}
                      {errors.assujettie_id && (
                        <div className="text-danger">
                          {errors.assujettie_id.message}
                        </div>
                      )}
                    </td>
                    {/* Conseils */}

                    <td style={{ borderLeft: "1px solid #dee2e6" }}>
                      {(doc.conseils || []).map((_, index) => (
                        <div
                          key={`conseil-${doc.id}-${index}`}
                          className="d-flex align-items-center mb-2"
                        >
                          <Controller
                            name={`conseil-${doc.id}-${index}`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                options={assujetties_acteurs} // Utilise les données des acteurs
                                optionLabel="name"
                                optionValue="id"
                                placeholder="Sélectionner un conseil..."
                                filter
                                className="w-100"
                                style={{
                                  borderRadius: "5px",
                                  border: "1px solid #ddd",
                                }}
                                onChange={(e) => {
                                  field.onChange(e.value); // Met à jour la valeur dans le formulaire
                                  handleChangeConseil(doc.id, index, e.value); // gestion spécifique
                                }}
                              />
                            )}
                          />
                          <span className="ms-2">
                            {index === 0 ? (
                              <FaPlusCircle
                                color="#28a745"
                                size={20}
                                style={{ cursor: "pointer" }}
                                onClick={() => addLine("conseils", doc.id)}
                              />
                            ) : (
                              <FaTimesCircle
                                color="#dc3545"
                                size={20}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  removeLine("conseils", doc.id, index)
                                }
                              />
                            )}
                          </span>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center mt-3">
            <Button
              variant="secondary"
              className="me-2"
              onClick={handleCloseModal}
            >
              Fermer
            </Button>
            <Button variant="success" type="button" onClick={handleSaveParties}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </Dialog>
    </div>
  );
};

export default TabResumeDossier;
