import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../admin/include/layout";
import { myroutes } from "../../../routes/webroute";
import $ from "jquery";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import "../../../styles/voirTout.css";
import AdministrationService from "../../../services/AdministrationService";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export default function Voirtout() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const profil_habilitation = useSelector((state) => state.auth.profil_habilitation);

  //Clear cache
  const handleClearCache = async () => {
    await AdministrationService.clear_cache(token).then(response => {
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: response.data.resultat
        });
      }
    }).catch(error => {
      Swal.fire({
        icon: "error",
        text: error.response.data.error
      });
      console.log(error)
    })
  }


   //Active storage
   const handleActiveStorage = async () => {
    await AdministrationService.active_storage(token).then(response => {
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: response.data.resultat
        });
      }
    }).catch(error => {
      Swal.fire({
        icon: "error",
        text: error.response.data.error
      });
      console.log(error)
    })
  }

  useEffect(() => {
    $("#parametres-nav").addClass("show");
  }, []);

  return (
    <Layout>
      <main id="main" className="main">
        <div className="container">
          <BreadcrumbComponent
            params={{ isParametre: true, libelle: "Voir tout" }}
            routeName={myroutes.voirtout}
          ></BreadcrumbComponent>
        </div>
        <div className="voir-tout container mt-2 p-4 shadow-sm bg-white rounded">
            {/**
          <h2 className="text-center text-success mb-4">Mes accès</h2> */}
          <div className="row text-left">
            {/* Groupe Gestion des Calendriers */}
            <div className="col-md-3 mb-4">
              <h5 className="section-title">Gestion des Calendriers</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to={myroutes.calendriers_index} className="menu-link">
                    Calendrier des audiences
                  </Link>
                </li>
                <li>
                  <Link
                    to={myroutes.calendriersglobals_index}
                    className="menu-link"
                  >
                    Calendrier global
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.joursferies_index} className="menu-link">
                    Calendrier des jours feriés
                  </Link>
                </li>
              </ul>
            </div>

            {/* Groupe Paramètres */}
            <div className="col-md-3 mb-4">
              <h5 className="section-title">Paramètres</h5>
              <ul className="list-unstyled">
              {(profil_habilitation.includes("ADMIN-RW") || profil_habilitation.includes("ADMIN-RO")) &&
              <>
              <li>
                  <Link onClick={() => handleActiveStorage()} className="menu-link">
                    Active storage
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.categories_index} className="menu-link">
                    Catégories
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleClearCache()} className="menu-link">
                    Clear cache
                  </Link>
                </li>
                <li>
                  <Link
                    to={myroutes.modespaiements_index}
                    className="menu-link"
                  >
                    Modes de paiements
                  </Link>
                </li></>
                }
                <li>
                  <Link
                    to={myroutes.motifsvalidations_index}
                    className="menu-link"
                  >
                    Motifs de validations
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.objets_index} className="menu-link">
                    Objets
                  </Link>
                </li> 
                <li>
                  <Link to={myroutes.statuts_index} className="menu-link">
                    Statuts
                  </Link>
                </li>               
                <li>
                  <Link
                    to={myroutes.typesdocuments_index}
                    className="menu-link"
                  >
                    Types documents
                  </Link>
                </li>
              </ul>
            </div>

            {/* Groupe Utilisateurs et Accès */}
            <div className="col-md-3 mb-4">
              <h5 className="section-title">Utilisateurs et Accès</h5>
              <ul className="list-unstyled">
                <li>
                  <Link
                    to={myroutes.chambresmembres_index}
                    className="menu-link"
                  >
                    Membres des chambres
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.habilitations_index} className="menu-link">
                    Habilitations
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.profils_index} className="menu-link">
                    Profils
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.utilisateurs_index} className="menu-link">
                    Utilisateurs
                  </Link>
                </li>
              </ul>
            </div>

            {/* Groupe Historique  */}
            <div className="col-md-3 mb-4">
              <h5 className="section-title">Historique</h5>
              <ul className="list-unstyled">
                <li>
                  <Link
                    to={myroutes.historiqueactions_index}
                    className="menu-link"
                  >
                    Historique des actions
                  </Link>
                </li>
                <li>
                  <Link
                    to={myroutes.historiqueconnexions_index}
                    className="menu-link"
                  >
                    Historique des connexions
                  </Link>
                </li>
                
              </ul>
            </div>
          </div>

          <div className="row text-left">
            {/* Groupe Gestion des Procédures */}
            <div className="col-md-3 mb-4">
              <h5 className="section-title">Gestion des Procédures</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to={myroutes.procedures_index} className="menu-link">
                    Procédures
                  </Link>
                </li> 
                <li>
                  <Link
                    to={myroutes.statutsdossiers_index}
                    className="menu-link"
                  >
                    Statuts des dossiers
                  </Link>
                </li>
                <li>
                  <Link
                    to={myroutes.statutsjuridiques_index}
                    className="menu-link"
                  >
                    Statuts juridiques
                  </Link>
                </li> 
                <li>
                  <Link
                    to={myroutes.typesparties_index}
                    className="menu-link"
                  >
                    Types de parties
                  </Link>
                </li>              
                <li>
                  <Link
                    to={myroutes.typesprocedures_index}
                    className="menu-link"
                  >
                    Types de procédures
                  </Link>
                </li>
              </ul>
            </div>

            {/* Groupe Organisation judiciaire */}
            <div className="col-md-3 mb-4">
              <h5 className="section-title">Organisation judiciaire</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to={myroutes.chambres_index} className="menu-link">
                    Chambres
                  </Link>
                </li>
                <li>
                  <Link
                    to={myroutes.natureaffaires_index}
                    className="menu-link"
                  >
                    Nature des affaires
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.parties_index} className="menu-link">
                    Parties
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.poles_index} className="menu-link">
                    Poles
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.tribunaux_index} className="menu-link">
                    Tribunaux
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.typesacteurs_index} className="menu-link">
                    Types d'acteurs
                  </Link>
                </li>
              </ul>
            </div>

            {/* Groupe Statistiques */}
            <div className="col-md-3 mb-4">
              <h5 className="section-title">Statistiques</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to={myroutes.statistiques} className="menu-link">
                  Statistiques
                  </Link>
                </li>                
              </ul>
            </div>

          </div>
        </div>
      </main>
    </Layout>
  );
}
