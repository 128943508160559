import http from "./http";

class ModesPaiementsService {
    indexFrontoffice(token){
        return http.get("/api/v01/web/modespaiements/liste",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    index(token){
        return http.get("/api/v01/web/modespaiements/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }
    
    store(data, token){
        return http.post("/api/v01/web/modespaiements/store", data, { headers: { 'Authorization': 'Bearer '+token } })
    } 
    edit(ref, token){
        return http.get(`/api/v01/web/modespaiements/${ref}/edit`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    show(ref, token){
        return http.get(`/api/v01/web/modespaiements/${ref}/show`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    update(ref, data, token){
        return http.put(`/api/v01/web/modespaiements/${ref}/update`, data,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    delete(ref,token){
        return http.delete(`/api/v01/web/modespaiements/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

}

export default new ModesPaiementsService()