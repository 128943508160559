import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery";
//import 'datatables.net-bs5/js/dataTables.bootstrap5.min.mjs'
//import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import JonctionsService from "../../../services/JonctionsService";
import {
  afficherErrorToast,
  afficherMessagesErreur,
  afficherMessagesSuccess,
  afficherOneMessageError,
  copyToClipboard,
  getColorStatus,
  setDataTable,
} from "../../../utils/helpers";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

import { Dialog } from "primereact/dialog";
import PhoneInput from "react-phone-number-input";
import { getButtonConfig } from "../../../utils/ButtonUtils";
import DossiersService from "../../../services/DossiersService";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { ProgressSpinner } from "primereact/progressspinner";

const schema = Joi.object({
  dossier_final: Joi.object({
    name: Joi.string().required().messages({
      "string.empty": "Le nom du dossier final est obligatoire.",
    }),
    id: Joi.number().required().messages({
      "number.base": "L'identifiant du dossier final doit être un nombre.",
      "any.required": "L'identifiant du dossier final est obligatoire.",
    }),
  })
    .required()
    .messages({
      "any.required": "Veuillez sélectionner un dossier final.",
      "object.base": "Le dossier final doit être un objet valide.",
    }),

  dossier_originaux: Joi.array()
    .items(
      Joi.number().required().messages({
        "number.base":
          "Chaque identifiant de dossier doit être un nombre valide.",
      })
    )
    .min(1)
    .required()
    .messages({
      "array.base": "La liste des dossiers à fusionner doit être un tableau.",
      "array.min": "Veuillez sélectionner au moins un dossier à fusionner.",
      "any.required": "La liste des dossiers à fusionner est obligatoire.",
    }),
});

export default function JonctionsIndex() {
  //##########################/// METHODES ##########################///
  const [jonctions, setJonctions] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [detailLabel, setDetailLabel] = useState("");
  const [titreModal, setTitreModal] = useState("");

  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const profils = useSelector((state) => state.auth.profil);

  const [showEditForm, setShowEditForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [editRef, setEditRef] = useState("");
  const [formData, setFormData] = useState({});

  //get liste jonctions
  const fetchJonctions = async () => {
    try {
      const response = await JonctionsService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setJonctions(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  const [showModalJonction, setShowModalJonction] = useState(false);
  const handleCloseModalJonction = () => setShowModalJonction(false);
  const handleShowModalJonction = async () => {
    //fetchDossiers();

    setShowModalJonction(true);
  };
  const [addFormData, setAddFormData] = useState({});
  const [validationsErrors, setValidationsErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dossiersOptions, setDossiersOptions] = useState([]);

  const [filteredFinalOptions, setFilteredFinalOptions] = useState([]);
  const handleChange = (e) => {
    setAddFormData({ ...addFormData, [e.target.name]: e.target.value });
  };

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
    //console.log(e)
  };

  const handleJonctionDossier = async () => {
    const validation = schema.validate(addFormData, { abortEarly: false });
    if (validation.error) {
      //console.error(validation.error.details);
      // Stocker les messages d'erreurs
      const errorMessages = Object.values(validation.error.details).map(
        (error) => error.message
      );
      setValidationsErrors(errorMessages);
      return;
    }
    setValidationsErrors([]);
    setShowModalJonction(false);
    setLoading(true);
    await DossiersService.jonctionDossier(addFormData, token)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          afficherMessagesSuccess(response.data.message);
          fetchJonctions();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        afficherMessagesErreur(error.response?.data);
        setLoading(false);
      });

    setLoading(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: joiResolver(schema),
  });

  // Gestion de la sélection des dossiers à fusionner
  const handleFilterFinalOptions = (selectedDossiers) => {
    // Assurez-vous que selectedDossiers est toujours un tableau
    const dossiersToExclude = Array.isArray(selectedDossiers)
      ? selectedDossiers
      : selectedDossiers
      ? [selectedDossiers] // Si c'est un objet, le transformer en tableau
      : []; // Si c'est null ou undefined, utiliser un tableau vide

    // Filtre les dossiers finaux en excluant ceux sélectionnés
    const filtered = dossiersOptions.filter(
      (dossier) =>
        !dossiersToExclude.some((selected_id) => selected_id === dossier.id)
    );
    //console.log(selectedDossiers)
    // Met à jour l'état des options filtrées
    setFilteredFinalOptions(filtered);
  };

  //get liste dossiers
  const fetchDossiers = async () => {
    try {
      const response = await DossiersService.listeDossierPayer(token);
      const responseData = response.data;

      // Récupération de la liste des statuts
      const responseStatut = await StatutsService.index(token);
      const statutsData = responseStatut.data.data;
      if (
        responseData.data &&
        responseData.data.length > 0 &&
        responseStatut.status == 200
      ) {
        //Afficher les dossiers dont paiement a été effectué
        const statutPayer = statutsData.find(
          (statut) => statut.libelle === "PAYE"
        );
        // Filtrer les dossiers pour ne garder que ceux avec un statut 'PAYE'
        const dossierPayer = responseData.data.filter((option) => {
          return option.get_paiement.some(
            (item) => parseInt(item.statut_id) == statutPayer.id
          ); //7 pour le statut PAYE
        });

        //Mapper les données pour les dropdown
        const data = dossierPayer.map((item) => {
          return {
            name: item.reference_dossier,
            id: item.id,
          };
        });
        setDossiersOptions(data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de dossiers:", error);
    }
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        JonctionsService.delete(param, token)
          .then((response) => {
            if (response.data.erreur) {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              /*setTimeout(() => {
                window.location.reload();
              }, 1000);*/
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.message,
            });
            console.log(e);
          });
      }
    });
  };

  const lauchEditForm = async (ref) => {
    //Lancer le modal d'edition
    try {
      const response = await JonctionsService.edit(ref, token);      

      if (response.data) {
        const data = response.data.data;
        // Mise à jour de l'état avec les données d'édition
        setEditData({
          //id: data.id,
          //ref: data.ref,
          dossier_originaux: dossiersOptions.find((opt) => opt.id === data.dossier_source_id),
          dossier_final: dossiersOptions.find((opt) => opt.id === data.dossier_destination_id),
        });

        setEditRef(ref);
        setShowEditForm(true);
        //setEditData({})
        //console.log(editData);
      } else {
        console.log("Data not found in API response");
      }
    } catch (error) {
      console.error("API error", error);
      Swal.fire({
        icon: "error",
        text: error.message,
      });
      //setValidationError(error.erreur);
    }
  };

  const handleSubmitUpdate = async () => {
    //Submit le formulaire d'edition
    setShowEditForm(false);
    const formValue = {
      dossier_originaux: editData.dossier_originaux.id,
      dossier_final: editData.dossier_final,
    }
    await JonctionsService.update(editRef, formValue, token)
      .then((response) => {
        if (response.data.status != true) {
          fetchJonctions();
          Swal.fire({
            icon: "error",
            text: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          //setValidationError(response.data.message)
        } else {
          //Actualiser les data
          fetchJonctions();
          afficherMessagesSuccess("Modification effectuée avec succès");
        }
      })
      .catch((error) => {
        afficherErrorToast(error.response?.data?.data)
        console.log(error.response.data)
      });
  };

  useEffect(() => {
    fetchJonctions();
    fetchDossiers();
    $("#dossiers-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Dossiers</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Liste des dossiers fusionnés
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center">
              <div className="col-md-12">
                <h3 className="app-page-title mb-3">
                  Liste des dossiers fusionnés &nbsp;
                  {(profils.includes("SUPERADMIN") ||
                    profils.includes("ADMIN") ||
                    profils.includes("AGEC")) && (
                    <>
                      <Link
                        className={`btn btn-sm btn-${
                          getButtonConfig("DELETE")?.color
                        }`}
                        title="Jonction de dossier"
                        onClick={handleShowModalJonction}
                      >
                        <i className="bi bi-journal-plus"></i>
                        &nbsp;Jonction de dossier
                      </Link>{" "}
                    </>
                  )}
                </h3>
              </div>
            </div>

            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Dossier fusionnés</th>
                        <th className="">Dossier final</th>
                        <th className="">Statut dossier final</th>
                        <th className="">Date fusion</th>
                        <th className="">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {jonctions.length > 0 &&
                        jonctions.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell">
                              <Link to={`${myroutes.dossiers_show}/${item.get_dossier_source?.ref}`} title="Voir" className="fw-bold">
                              {item.get_dossier_source?.reference_dossier}</Link>
                            </td>
                            <td className="cell">
                            <Link to={`${myroutes.dossiers_show}/${item.get_dossier_destination?.ref}`} title="Voir" className="fw-bold">
                            {item.get_dossier_destination?.reference_dossier}</Link>
                            </td>
                            <td className="cell">
                              <span
                                className="badge"
                                style={{
                                  backgroundColor: getColorStatus(
                                    item.get_dossier_destination
                                      ?.statut_dossier_id
                                  ),
                                }}
                              >
                                {item.get_dossier_destination &&
                                  item.get_dossier_destination
                                    .get_statut_dossier &&
                                  item.get_dossier_destination
                                    .get_statut_dossier.libelle}
                              </span>
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.updated_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>{" "}
                              <br />
                              <b>
                                {item.get_updated_by?.nom +
                                  " " +
                                  item.get_updated_by?.prenoms}
                              </b>
                            </td>
                            <td className="cell">
                              <div className="d-flex align-items-center gap-1">
                                <Button
                                  className={`btn btn-sm btn-${
                                    getButtonConfig("EDIT")?.color
                                  }`}
                                  onClick={() => lauchEditForm(item.ref)}
                                  title="Modifier"
                                >
                                  <i
                                    className={`fa ${
                                      getButtonConfig("EDIT")?.icon
                                    }`}
                                  ></i>
                                </Button>{" "}
                                &nbsp;&nbsp;
                                <Button
                                  className={`btn btn-sm btn-${
                                    getButtonConfig("DELETE")?.color
                                  }`}
                                  onClick={() => onDelete(item.ref)}
                                  title="Supprimer"
                                >
                                  <i
                                    className={`fa ${
                                      getButtonConfig("DELETE")?.icon
                                    }`}
                                  ></i>
                                </Button>{" "}
                                &nbsp;&nbsp;
                                <Button
                                  className="btn btn-sm btn-success"
                                  onClick={() =>
                                    copyToClipboard(
                                      item.get_dossier_source
                                        ?.reference_dossier +
                                        " => " +
                                        item.get_dossier_destination
                                          ?.reference_dossier
                                    )
                                  }
                                >
                                  <i className="bi bi-clipboard2-fill"></i> Ref
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/***Modal Jonction de dossier */}
            <Dialog
              header={"Jonction de dossier"}
              visible={showModalJonction}
              maximizable
              onHide={handleCloseModalJonction}
              style={{
                width: "700px",
                borderBottom: "1px solid grey",
                color: "GrayText",
              }}
              headerStyle={{ background: "#f0f0f0" }}
            >
              <div className="row">
                {validationsErrors.length > 0 && (
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <ul>
                        {validationsErrors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <Form>
                {/* Dossiers à fusionner */}
                <Col md={12} sm={12} className="formx-groupx">
                  <Form.Group className="mb-3 mt-4">
                    <Form.Label className="fw-bold">
                      Dossiers à fusionner <i className="text-danger">*</i>
                    </Form.Label>
                    <MultiSelect
                      {...register("dossier_originaux")}
                      value={addFormData.dossier_originaux}
                      onChange={(e) => {
                        handleChange(e);
                        handleFilterFinalOptions(e.value);
                      }}
                      options={dossiersOptions}
                      optionLabel="name"
                      optionValue="id"
                      filter
                      display="chip"
                      placeholder="Sélectionnez les dossiers à fusionner..."
                      className={`w-100 h-50 ${
                        errors.dossier_originaux ? "is-invalid" : ""
                      }`}
                      name="dossier_originaux"
                      required
                    />
                  </Form.Group>
                  <div className="invalid-feedback">
                    {errors.dossier_originaux?.message}
                  </div>
                </Col>

                {/* Dossier final */}
                <Col md={12} sm={12} className="formx-groupx">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">
                      Dossier final <i className="text-danger">*</i>
                    </Form.Label>
                    <Dropdown
                      {...register("dossier_final")}
                      value={addFormData.dossier_final}
                      id="dossier_final"
                      required
                      name="dossier_final"
                      onChange={handleChange}
                      options={filteredFinalOptions}
                      optionLabel="name"
                      placeholder="Sélectionnez le dossier final..."
                      filter
                      className={`w-100 h-50 ${
                        errors.dossier_final ? "is-invalid" : ""
                      }`}
                      checkmark={true}
                    />
                  </Form.Group>
                  <div className="invalid-feedback">
                    {errors.dossier_final?.message}
                  </div>
                </Col>

                {/* Footer */}
                <div className="p-dialog-footerx mt-4">
                  <Button
                    type="button"
                    onClick={handleJonctionDossier}
                    variant="warning me-3"
                  >
                    Enregistrer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleCloseModalJonction}
                  >
                    Annuler
                  </Button>
                </div>
              </Form>
            </Dialog>

            {/***End Modal Jonction de dossier */}

            {/****Mode d'edition */}
            <Dialog
              header="Modifier cette jonction"
              visible={showEditForm}
              onHide={() => setShowEditForm(false)}
            >
              <div className="app-card-body py-3">
                <Form style={{ textAlign: "left" }}>
                  {/* Dossiers à fusionner */}
                  <Col md={12} sm={12} className="formx-groupx">
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bold">
                        Dossier à fusionner
                        <i className="text-danger">*</i>
                      </Form.Label>
                      <Dropdown
                        value={editData.dossier_originaux}
                        id="dossier_originaux"
                        required
                        name="dossier_originaux"
                        onChange={(e) => {
                          handleEditChange(e);
                        }}
                        options={dossiersOptions}
                        optionLabel="name"
                        placeholder="Sélectionnez le dossier à fusionner..."
                        filter
                        className={`w-100 h-50 `}
                        checkmark={true}
                      />
                    </Form.Group>
                  </Col>

                  {/* Dossier final */}
                  <Col md={12} sm={12} className="formx-groupx">
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bold">
                        Dossier final <i className="text-danger">*</i>
                      </Form.Label>
                      <Dropdown
                        value={editData.dossier_final}
                        id="dossier_final"
                        required
                        name="dossier_final"
                        onChange={(e) => handleEditChange(e)}
                        options={dossiersOptions}
                        optionLabel="name"
                        placeholder="Sélectionnez le dossier final..."
                        filter
                        className={`w-100 h-50 `}
                        checkmark={true}
                      />
                    </Form.Group>
                  </Col>
                  <Button
                    type="button"
                    onClick={handleSubmitUpdate}
                    variant="warning me-3"
                  >
                    Enregistrer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditForm(false)}
                  >
                    Annuler
                  </Button>
                </Form>
              </div>
            </Dialog>

            <Modal show={loading} backdrop="static" keyboard={false} centered>
              <Modal.Body className="text-center">
                <ProgressSpinner />
                <p className="my-3 fw-bold" style={{ fontSize: "18px" }}>
                  Veuillez patienter pendant l'enregistrement ...
                </p>
              </Modal.Body>
            </Modal>

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
